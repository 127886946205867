<template>
  <client-only>
    <aside
      v-show="summoned"
      class="app-summons"
    >
      <template v-if="summoned && isActive">
        <component
          :is="`mtf-summon-${summoned.type}`"
          :key="`summoned-${summoned.id}`"
          :model-value="isActive"
          v-bind="summoned.props"
          :close-icon="summoned.closeIcon"
          :close-label="summoned.closeLabel"
          @update:model-value="!$event && doClose()"
        >
          <v-alert
            :text="summoned.error"
            type="error"
            variant="flat"
            density="compact"
            :model-value="meta.failed"
            class="mb-4 w-100"
            :rounded="summoned.type == 'drawer' ? 0 : true"
          />

          <component
            :is="summoned.Component"
            class="pb-6"
            v-bind="summoned.params"
            :model-value="summoned.model"
            @update:valid="doValidate"
            @update:model-value="doUpdate"
            @cancel="doClose"
          />

          <template #actions>
            <v-spacer v-if="summoned.type == 'dialog'" />

            <v-btn
              variant="plain"
              :disabled="meta.processing"
              :prepend-icon="summoned.closeIcon"
              @click="doClose"
            >
              {{ summoned.closeLabel }}
            </v-btn>

            <v-spacer v-if="summoned.type == 'drawer'" />

            <template
              v-for="(action, actionId) in summoned.actions"
              :key="`action-${actionId}`"
            >
              <v-btn
                v-if="useCheckConditions(action.conditions, action)"
                v-bind="action.props"
                :disabled="meta.disabled"
                :loading="meta.processing"
                @click="doAction(actionId)"
              >
                {{ action.label || 'ok' }}
              </v-btn>
            </template>
          </template>
        </component>
      </template>
    </aside>
  </client-only>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useMatterificSummonStore } from '@matterific.summons/store';
import { useCheckConditions } from '@matterific/utils/conditions';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'MtfSummons',
  components: {
    MtfSummonDrawer: defineAsyncComponent(() => import('@matterific/components/Drawer.vue')),
    MtfSummonDialog: defineAsyncComponent(() => import('@matterific/components/Dialog.vue'))
  },
  inheritAttrs: false,
  customOptions: {},

  setup() {
    const store = useMatterificSummonStore();
    const { summoned, isActive, meta } = storeToRefs(store);

    return {
      summoned,
      meta,
      // ------------------------------
      isActive,
      // ------------------------------
      doUpdate: store.update,
      doValidate: store.validate,
      doAction: store.process,
      doClose: store.close,
      clear: store.clear,
      add: store.add,
      // ------------------------------
      useCheckConditions
    };
  }
});
</script>
