import { faker } from '@faker-js/faker';
import { random, nth } from 'lodash-es';

export const generateData = (type) => {
  type ??= nth(['dialog', 'drawer'], random(0, 1));
  const icon = nth(
    [
      'mdi-information',
      'mdi-alert-circle',
      'mdi-alert',
      'mdi-check',
      'mdi-passport-biometric',
      'mdi-account-circle'
    ],
    random(0, 5)
  );
  const color = nth(
    ['', 'info', 'error', 'warning', 'success', 'primary', 'secondary'],
    random(0, 6)
  );

  const summoned = {
    type,
    Component: 'mtf-section',
    props: {
      theme: random(0, 1) ? 'light' : 'dark',
      color,
      icon,
      persistent: true
    },

    actions: {
      valid: {
        label: faker.lorem.word(),
        props: {
          prependIcon: icon
          // color: type == 'snackbar' ? null : color,
          // variant: nth(['tonal', 'flat', 'plain', 'elevated',], random(0, 3))
        },
        resolve: () =>
          new Promise((resolve, reject) => {
            if (random(0, 1)) {
              setTimeout(resolve, 800); // allow time for the loading  micro animation
            } else {
              setTimeout(() => reject(faker.lorem.sentence()), 800); // allow time for the loading  micro animation
            }
          })
      }
    },

    params: {
      content: {
        styles: ['py-4', { 'px-4': type == 'drawer' }],
        title: faker.lorem.sentence(),
        strapline: faker.lorem.sentence(),
        excerpt: faker.lorem.paragraph(),
        copy: faker.lorem.paragraphs()
      }
    }
  };

  return summoned;
};

const examples = [];

for (let index = 0; index < 3; index++) {
  examples.push(generateData());
}

export default examples;
