import { getActivePinia } from 'pinia';
import { every, isArray, isEmpty, get, isFunction } from 'lodash-es';

const assert = (condition, context) => {
  if (!condition?.context) return true; // if we dont have a context to check then just bail out
  let value = get(context, condition?.context, false);

  if (isFunction(value)) {
    value = value(condition?.param);
  }
  switch (condition?.operator) {
    default:
    case '==':
      return value == condition.assert;
    case '!=':
      return value != condition.assert;
    case '>':
      return value > condition.assert;
    case '>=':
      return value >= condition.assert;
    case '<':
      return value < condition.assert;
    case '<=':
      return value <= condition.assert;
    case 'in':
      return condition.assert.includes(value);
    case 'not in':
      return !condition.assert.includes(value);
    case 'contains':
      return condition.assert.includes(value);
    case 'is empty':
      return isEmpty(value);
    case 'is not empty':
      return !isEmpty(value);
    case 'size ==':
      return value?.length == condition.assert;
    case 'size >':
      return value?.length > condition.assert;
    case 'size <':
      return value?.length < condition.assert;
    case 'size >=':
      return value?.length >= condition.assert;
    case 'size <=':
      return value?.length <= condition.assert;
  }
};

function assertState(condition, state) {
  if (!state) return false;
  const safeMatches = isArray(condition.matches) ? condition.matches : [condition.matches];
  return safeMatches.some(state.matches) == condition.assert;
}

export const useCheckConditions = (conditions, context) => {
  if (isEmpty(conditions)) return true;

  return every(conditions, (condition) => {
    if (condition.store) {
      const pinia = getActivePinia();
      const store = pinia._s.get(condition.store); //get(pinia.state.value, value.store);
      // matches is spcecifically for checking state machines
      if (condition?.matches) {
        return assertState(condition, store?.state);
      } else {
        return assert(condition, store);
      }
    } else {
      return assert(condition, context);
    }
  });
};
