<template>
  <v-toolbar
    v-if="title || hasActions || hasMenu"
    v-bind="safeAttrs"
    class="px-2"
    :title="title"
  >
    <v-spacer />
    <slot name="actions">
      <mtf-actions
        v-if="hasActions"
        :actions="actions"
        :disabled="disabled"
        @action="$emit('action', $event)"
      />
    </slot>
    <slot name="menu">
      <v-menu
        v-if="hasMenu"
        location="bottom right"
        :disabled="disabled"
      >
        <template #activator="{ props: menuProps }">
          <v-btn
            v-bind="menuProps"
            icon="mdi-dots-vertical"
            class="mx-0"
            rounded="0"
          />
        </template>
        <v-list
          dense
          nav
        >
          <template
            v-for="(action, name) in menuActions"
            :key="`action-${name}`"
          >
            <v-divider v-if="action.divider" />

            <v-list-subheader
              v-else-if="action.subheader"
              :title="action.label"
              v-bind="action.subheader"
            />

            <v-list-item
              v-else-if="useCheckConditions(action.conditions)"
              :value="name"
              :title="action.label"
              :prepend-icon="action.icon"
              :to="useSafeValue(action.to)"
              link
              exact
              @click="
                $emit('action', {
                  command: action.command,
                  context: null,
                  event: action.event
                })
              "
            />
          </template>
        </v-list>
      </v-menu>
    </slot>
  </v-toolbar>

  <mtf-form-machine
    v-bind="$attrs"
    :id="id"
    :key="`${entity}-${id}`"
    :dismissable="dismissable"
    :timeout="timeout"
    @action="$emit('action', $event)"
    @cancel="$emit('cancel', $event)"
  />
</template>

<script>
import { defineComponent } from 'vue';
import { useMatterificEntityMachine, useMatterificEntryMachine } from '#imports';
import { useSelector } from '@xstate/vue';
import { useSafeValue, useCheckConditions } from '@matterific/utils';
import MtfActions from '@matterific/components/Actions.vue';
import { isEmpty } from 'lodash-es';

export default defineComponent({
  name: 'MtfManageEntity',
  components: { MtfActions },
  provide() {
    return {
      formMachine: this.machine
    };
  },
  inheritAttrs: true,
  customOptions: {},
  // --------
  props: {
    entity: { type: String, required: true },
    parent: { type: String },
    id: { type: String },
    dismissable: Boolean,
    timeout: Number,
    title: String,
    // ---
    actions: { type: Object, default: () => {} },
    menuActions: { type: Object, default: () => {} },
    // ---
    loading: Boolean,
    disabled: Boolean
  },
  emits: ['cancel', 'action'],
  // ----------------

  setup(props, { attrs }) {
    const machine = props.parent
      ? useMatterificEntryMachine(props.entity, props.parent, props)
      : useMatterificEntityMachine(props.entity, props);

    const config = useSelector(machine.service, (state) => state.context.config);

    const safeAttrs = computed(() => ({
      color: config.value?.color,
      rounded: true,
      sticky: true,
      ...attrs
    }));
    return {
      safeAttrs,
      useSafeValue,
      useCheckConditions,
      config,
      machine
    };
  },
  computed: {
    isNew() {
      return !this.id || this.id === 'new';
    },
    hasActions() {
      return !isEmpty(this.actions);
    },
    hasMenu() {
      return !isEmpty(this.menuActions);
    }
  }
});
</script>
