<template>
  <v-fade-transition
    group
    mode="out-in"
    class="d-flex flex-wrap"
    :class="['subsections', styles]"
    tag="aside"
  >
    <slot>
      <mtf-section
        v-for="(section, index) in sections"
        :key="`section-${index}`"
        v-bind="section"
        :organisms="null"
      />
    </slot>
  </v-fade-transition>
</template>

<script>
export default defineComponent({
  name: 'MtfSubsections',
  props: {
    variant: String,
    styles: [String, Object, Array],
    sections: Array
  }
});
</script>
