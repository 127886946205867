import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { isEmpty, set, trimStart, trim, isString } from 'lodash-es';
import { isValidPhoneNumber } from 'libphonenumber-js';
import ct from 'countries-and-timezones';

export const useMatterificValidation = () => {
  const ajv = new Ajv({ strict: false, allErrors: true, discriminator: true });
  // const ajv = new Ajv({ strict: false, verbose: true, allErrors: true, coerceTypes: true, useDefaults: true });
  addFormats(ajv);

  ajv.addKeyword({
    keyword: 'isNotEmpty',
    type: ['string', 'object', 'array'],
    schemaType: 'boolean',
    validate: (schema, data) => !isEmpty(trim(data)),
    error: {
      message: 'must not be empty'
    }
  });

  // Phone number validation
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultCountry = ct.getCountryForTimezone(timezone);

  ajv.addKeyword({
    keyword: 'isPhoneNumber',
    type: ['string', 'object'],
    schemaType: 'boolean',
    validate: (schema, data) => {
      const phoneNumber = isString(data) ? data : data?.national || data?.number || '';
      const country = data?.countryCode || defaultCountry?.id;
      return isValidPhoneNumber(phoneNumber, country);
    },
    error: {
      message: 'must be a valid phone number'
    }
  });

  function validate(schema, model) {
    model ??= {}; // ensure model is not null
    const validate = ajv.compile(schema);
    const isValid = validate(model);

    return new Promise((resolve, reject) => {
      if (isValid) {
        resolve();
      } else {
        const errors = validate.errors.reduce((errors, error) => {
          const { instancePath, message, params } = error;
          const path = trimStart(instancePath, '/').split('/');

          if (error.keyword === 'required') {
            path.push(params.missingProperty);
          }

          set(errors, [`${path.join('.')}`], message);

          return errors;
        }, {});
        reject(errors);
      }
    });
  }

  return {
    validate
  };
};
