import { createMachine, assign } from 'xstate';
export default createMachine(
  {
    id: 'matterific.auth',
    predictableActionArguments: true,
    initial: 'authenticating', // we want to start by checking if user is logged in when page loads

    context: {
      auth: null,
      user: null,
      error: null,
      rules: null // TODO
    },

    states: {
      authenticating: {
        // when entering a state invoke the authChecker service
        invoke: {
          id: 'authChecker',
          src: 'authChecker',
          onDone: { target: 'pending', actions: ['setAuth'] },
          onError: { target: 'unauthenticated', actions: [] }
        }
      },

      // we will enrich the user profile  with additional data
      pending: {
        invoke: {
          id: 'loader',
          src: 'loader',
          onDone: { target: 'authenticated', actions: ['setUser'] },
          onError: [
            {
              target: 'authenticated.invalid',
              actions: ['setUser'],
              cond: (context, { data }) => (data.statusCode = 400)
            },
            {
              target: 'unauthenticated.failure',
              actions: ['setError', 'clearAuth']
            }
          ]
        }
      },

      authenticated: {
        initial: 'valid',
        states: {
          valid: {},
          invalid: {}
        },
        // when receiving 'logout' event transition to singingOut state
        on: { logout: { target: 'signingOut' } }
      },

      // unauthenticated has two sub-states we will transition to failure in
      // case of wrong password, username or network error
      unauthenticated: {
        initial: 'valid',
        states: {
          valid: { type: 'final' },
          failure: {
            after: {
              // after 5 seconds, clear the error
              5000: { target: 'valid', actions: ['clearError'] }
            }
          }
        },
        on: {
          login: { target: 'signingIn' },
          forgot: { target: 'resetting' }
        }
      },

      resetting: {
        invoke: {
          id: 'forgotPassword',
          src: 'forgotPassword',
          onDone: { target: 'unauthenticated.failure', actions: ['setError'] },
          onError: { target: 'unauthenticated.failure', actions: ['setError'] }
        }
      },

      signingIn: {
        invoke: {
          id: 'login',
          src: 'login',
          onDone: { target: 'authenticating', actions: ['clearError'] },
          onError: [
            {
              target: 'unauthenticated',
              actions: ['clearAuth'],
              cond: (context, { data }) => data.statusMessage == 'timeout'
            },
            { target: 'unauthenticated.failure', actions: ['setError'] }
          ]
        }
      },

      signingOut: {
        invoke: {
          id: 'logout',
          src: 'logout',
          onDone: {
            target: 'unauthenticated',
            actions: ['clearAuth', 'clearError']
          },
          onError: {
            target: 'unauthenticated.failure',
            actions: ['clearAuth', 'setError']
          }
        }
      }
    }
  },
  {
    actions: {
      clearAuth: assign({ user: null, auth: null }), // clear user info on logout
      clearError: assign({ error: null }),
      setAuth: assign({ auth: (context, { data }) => data }), // put Firebase auth object on context
      setUser: assign({ user: (context, { data }) => data }), // put user on context in pending service
      setError: assign({ error: (context, { data }) => data })
    },
    services: {
      // services need to be passed in during the machine creation
    }
  }
);
