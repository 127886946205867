import { createMachine, assign, send } from 'xstate';
import api from '../services'; // this is not a composable so we can reference it directly
import { first, map, reject } from 'lodash-es';

export default createMachine(
  {
    id: 'matterific.messages',
    predictableActionArguments: true,
    preserveActionOrder: true,
    initial: 'loading',

    context: {
      active: null,
      activeRef: null,
      items: []
    },

    states: {
      loading: {
        invoke: {
          id: 'rehydrate',
          src: 'rehydrate',
          onDone: { target: 'pending', actions: ['set'] }
        }
      },

      pending: {
        invoke: {
          id: 'queue',
          src: 'queue',
          onDone: { target: 'active', actions: ['setActive'] },
          onError: { target: 'available', actions: ['clearActive'] }
        }
      },

      active: {
        entry: assign({
          activeRef: (context) => api.useActor(context.active)
        }),
        on: {
          dismiss: {
            actions: send({ type: 'dismiss' }, { to: (context) => context.activeRef })
          },
          remove: {
            target: 'pending',
            actions: ['log', 'stopActive', 'remove', 'clearActive']
          },

          update: {
            actions: ['update']
          }
        }
      },

      available: {}
    },

    on: {
      add: {
        target: 'pending',
        actions: ['add']
        // cond: (context, {data}) => !!data.text // todo : proper validation
      },
      clear: {
        target: 'pending',
        actions: ['clear']
      }
    }
  },
  {
    actions: {
      set: assign({ items: (context, { data }) => data }), // put Firebase auth object on context
      clear: assign({ items: [] }), // put Firebase auth object on context

      setActive: assign({ active: (context, { data }) => data }), // put Firebase auth object on context
      clearActive: assign({ active: null, activeRef: null }), // clear user info on logout
      stopActive: (context) => context.activeRef.stop(),

      add: assign({
        items: (context, { data }) => context.items.concat(api.hydrate(data))
      }),

      update: assign({
        items: (context, { id, data }) =>
          context.items.map((message) => {
            return message.id === id ? { ...message, ...data } : message;
          })
      }),

      remove: assign({
        items: (context, { id }) => reject(context.items, ['id', id])
      })
    },

    services: {
      rehydrate: (context) =>
        new Promise((resolve) => resolve(map(context.items, (message) => api.hydrate(message)))),
      queue: (context) =>
        new Promise((resolve, reject) =>
          context.items?.length ? resolve(first(context.items)) : reject()
        )
    }
  }
);
