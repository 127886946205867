/**
 * Set permissions for an admin
 *
 * @param  {object} context - context of the current user or request
 * @param  {object} AbilityBuilder - the casl ability instance
 */
function setPerms(context, { can }) {
  can('manage', 'all');
}

export default {
  name: 'admin',
  title: 'Admin',
  description: 'Full access to all resources',
  icon: 'mdi-shield-account',
  color: 'admin',
  setPerms
};
