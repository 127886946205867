export const schema = {
  fields: {
    uniqueItems: true,
    type: 'array',
    items: {
      type: 'object',
      dependentRequired: {
        matches: ['store'],
        context: ['store', 'assert', 'operator']
      },
      properties: {
        matches: {
          type: ['string', 'array'],
          isNotEmpty: true
        },
        context: {
          type: 'string',
          isNotEmpty: true
        },
        store: {
          type: 'string',
          isNotEmpty: true
        },
        assert: {
          type: ['string', 'boolean', 'number', 'array', 'object', 'null'],
          isNotEmpty: true
        },
        operator: {
          enum: [
            '==',
            '!=',
            '>',
            '>=',
            '<',
            '<=',
            'in',
            'not in',
            'contains',
            'is empty',
            'is not empty',
            'size ==',
            'size >',
            'size <',
            'size >=',
            'size <='
          ],
          type: 'string',
          default: '==',
          isNotEmpty: true
        },
        param: {
          type: ['string', 'boolean', 'number', 'array', 'object', 'null'],
          isNotEmpty: true
        }
      }
    }
  }
};

export const uischema = {
  form: {
    fields: [
      {
        name: 'conditions',
        label: 'Conditions',
        control: 'repeater',
        options: {
          prependInnerIcon: 'mdi-land-rows-horizontal',
          persistHint: true,
          hint: 'Add conditions to control when this entity is valid to the user',
          rowLabel: (context) => `Condition ${context?.index}`
        },
        uischema: {
          fields: [
            // todo: add the condition repeater's fields here
          ]
        }
      }
    ]
  }
};

export default {
  schema,
  uischema
};
