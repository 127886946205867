/**
 * Set permissions for a member
 *
 * @param  {object} context - context of the current user or request
 * @param  {object} AbilityBuilder - the casl ability instance
 */
function setPerms(context, { can }) {
  // users can see their own profile only, but cannot edit or delete themselves
  can('read', 'users', { user_id: context?.uid });
  // can('update', 'users', { user_id: context?.uid });
  // can('remove', 'users', { user_id: context?.uid });
}

export default {
  name: 'member',
  title: 'Member',
  description: 'Limited access to resources',
  icon: 'mdi-account-check',
  color: '',
  setPerms
};
