<template>
  <v-footer
    v-bind="safeAttrs"
    :key="timestamp"
  >
    <v-row
      align="center"
      justify="center"
      no-gutters
    >
      <v-col
        class="text-center text-md-left my-3"
        cols="12"
        md="5"
      >
        <span>
          &copy; {{ new Date().getFullYear() }} <span v-if="companyName">{{ companyName }}</span> -
          All Rights Reserved
        </span>
      </v-col>

      <v-col
        class="text-center order-first order-md-0 my-3"
        cols="12"
        md="2"
      >
        <mtf-link
          :class="[{ 'd-sr-only': !branding }, `text-${color}`]"
          class="d-block logo mx-auto"
          :to="safeTo"
        >
          <mtf-icon
            name="logo-footer"
            filled
          />
          <span
            v-if="companyName"
            class="d-sr-only"
          >
            {{ companyName }}
          </span>
        </mtf-link>
      </v-col>

      <v-col
        class="text-center text-md-right my-3"
        cols="12"
        md="5"
      >
        <v-spacer />
        <slot name="compliance">
          <!--LOADING  -->
          <v-progress-circular
            v-if="loading"
            :color="color"
            indeterminate
            size="x-small"
            width="1"
          />

          <mtf-menu
            v-if="!loading && hasComplianceMenu"
            class="d-inline-flex justify-center justify-md-end"
            :class="`text-${color}`"
            :items="menuCompliance.items"
            :conditions="menuCompliance?.conditions"
            :bg-color="background"
            :color="color"
          />
        </slot>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { useAppConfig } from '#imports';
import MtfLink from '@matterific/components/Link.vue';
import MtfIcon from '@matterific/components/Icon.vue';

export default defineComponent({
  name: 'MtfFooter',
  components: {
    MtfLink,
    MtfIcon
  },
  inheritAttrs: false,
  customOptions: {},
  props: {
    company: String,
    to: {
      type: [String, Object],
      required: false
    },
    branding: { type: Boolean, default: true },
    background: String,
    color: String,
    loading: { type: Boolean, default: false },
    //
    menuCompliance: { type: [String, Object] }
    // menuFooter: { type: [String, Object,] },
    // menuSocial: { type: [String, Object,] }
  },
  setup(props, { slots, attrs }) {
    // --------
    // PRIVATE
    const config = useAppConfig();

    const {
      company,
      to,
      background,
      color,
      menuCompliance
      // menuFooter,
      // menuSocial
    } = toRefs(props);

    const companyName = computed(() => company.value || config?.title);
    const safeTo = computed(() => to.value || '/');

    const safeAttrs = computed(() => ({
      ...attrs,
      absolute: true,
      // app: true,
      class: ['w-100', 'text-footer', `text-${color.value}`],
      color: background.value
    }));

    // --------
    const hasComplianceMenu = computed(
      () => !!slots.compliance || !!menuCompliance.value?.items?.length
    );
    const timestamp = ref(Date.now());

    return {
      timestamp,
      companyName,
      safeTo,
      // footerMenu,
      // socialMenu,
      hasComplianceMenu,
      // hasFooterMenu,
      // hasSocialMenu,
      safeAttrs
    };
  },
  mounted() {
    this.$nextTick(() => {
      const newTimestamp = Date.now();
      // console.log('footer mounted, refreshing ui with timestamp', { timestamp: this.timestamp, newTimestamp });
      this.timestamp = newTimestamp;
    });
  }
});
</script>

<style lang="scss" scoped>
.icon {
  height: 60px !important;
}

// ::v-deep {
//   .navitem {
//     flex: none;
//   }

//   .v-list-item {
//     // font: inherit !important;
//     letter-spacing: inherit !important;

//     .v-list-item__title {
//       font-size: inherit !important;
//     }
//   }
// }
</style>
