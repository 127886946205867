export const autocomplete = {
  schema: { autocomplete: { type: ['null', 'string'] } },
  uischema: {
    name: 'options.autocomplete',
    label: 'Autocomplete Input from browser',
    control: 'text',
    options: {
      hint: 'Name of the autocomplete attribute, for a list of supported values see: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values',
      prependInnerIcon: 'mdi-auto-fix'
    }
  }
};

export const autofocus = {
  schema: { autofocus: { type: ['null', 'boolean'] } },
  uischema: {
    name: 'options.autofocus',
    label: 'Autofocus',
    control: 'checkbox',
    options: {
      hint: 'Focus the input on load, should only be used on a single input in the form/field group',
      prependIcon: 'mdi-focus-auto'
    }
  }
};

export const autogrow = {
  schema: { autogrow: { type: ['null', 'boolean'] } },
  uischema: {
    name: 'options.autogrow',
    label: 'Autogrow to fit content',
    control: 'checkbox',
    options: {
      hint: 'Autogrow the input to fit the content',
      prependIcon: 'mdi-resize'
    }
  }
};

export const chips = {
  schema: { chips: { type: ['null', 'boolean'] } },
  uischema: {
    name: 'options.chips',
    label: 'Show Chips',
    control: 'text',
    options: {
      hint: 'Show chips for the selected items. Chips allow selected items to be individually removable and reorderable by the user',
      prependInnerIcon: 'mdi-tag'
    }
  }
};

export const clearable = {
  schema: { clearable: { type: ['null', 'boolean'] } },
  uischema: {
    name: 'options.clearable',
    label: 'Clearable',
    control: 'checkbox',
    options: {
      hint: 'Allow the user to clear the input',
      prependIcon: 'mdi-refresh'
    }
  }
};

export const counter = {
  schema: { counter: { type: ['null', 'boolean'] } },
  uischema: {
    name: 'options.counter',
    label: 'Counter',
    control: 'checkbox',
    options: {
      hint: 'Show a counter of the number of characters entered',
      prependIcon: 'mdi-counter'
    }
  }
};

export const hint = {
  schema: { hint: { type: ['null', 'string'] } },
  uischema: {
    name: 'options.hint',
    label: 'Hint',
    control: 'text',
    options: {
      hint: 'Give the user a hint about what to enter',
      prependInnerIcon: 'mdi-assistant'
    }
  }
};

export const multiple = {
  schema: { multiple: { type: ['null', 'boolean'] } },
  uischema: {
    name: 'options.multiple',
    label: 'Allow Multiple Selections',
    control: 'checkbox',
    options: {
      hint: 'Allow the user to select multiple items',
      prependIcon: 'mdi-checkbox-multiple-marked'
    }
  }
};

export const placeholder = {
  schema: { placeholder: { type: ['null', 'string'] } },
  uischema: {
    name: 'options.placeholder',
    label: 'Placeholder',
    control: 'text',
    options: {
      hint: 'Placeholder text to display when the input is empty',
      prependInnerIcon: 'mdi-format-letter-starts-with'
    }
  }
};

export const rows = {
  schema: { rows: { type: ['null', 'number'], minValue: 0 } },
  uischema: {
    name: 'options.rows',
    label: 'rows',
    control: 'number',
    options: {
      hint: 'Number of rows to display for the input, this will have an impact on the height of the input',
      prependInnerIcon: 'mdi-arrow-expand-vertical',
      step: 1,
      min: 0
    }
  }
};

// items sub-schema, used for array fields

export const items = {
  schema: {
    items: {
      type: ['null', 'array'],
      items: {
        type: 'object',
        required: ['title', 'value'],
        minItems: 3,
        default: [{}, {}, {}], // default to 3 empty items
        isNotEmpty: true,
        properties: {
          title: { type: ['string'], isNotEmpty: true },
          value: {
            type: ['string'],
            isNotEmpty: true,
            pattern: '^[a-z,0-9]+(?:-[a-z,0-9]+)*$'
          }
        }
      }
    }
  },
  uischema: {
    name: 'options.items',
    label: 'rows',
    control: 'repeater',
    options: {
      hint: 'The minimum of 3 choices for the user to select from. Each item/choice requires a title and value. If you have fewer than 3 choices, you should consider using a radio group instead',
      prependInnerIcon: 'mdi-form-dropdown',
      min: 3
    },
    uischema: {
      fields: [
        {
          name: 'value',
          label: 'Value',
          control: 'text',
          options: {
            autofocus: true,
            hint: 'The value that the data must match',
            prependInnerIcon: 'mdi-text'
          }
        },
        {
          name: 'title',
          label: 'Label',
          control: 'text',
          options: {
            hint: 'The label that is displayed to the user',
            prependInnerIcon: 'mdi-format-title'
          }
        }
      ]
    }
  }
};

export const itemsFromStore = {
  schema: {
    items: {
      type: 'object',
      required: ['store', 'context'],
      properties: {
        store: { type: ['null', 'string'] },
        context: { type: ['null', 'string'] }
      }
    }
  },
  uischema: {
    name: 'options.items',
    label: 'Lookup Items from store',
    control: 'group',
    options: {
      hint: 'The items to display in the lookup',
      prependInnerIcon: 'mdi-form-dropdown'
    },
    uischema: {
      options: {
        variant: 'text'
      },
      fields: [
        {
          name: 'store',
          label: 'Store Name',
          control: 'text', // lookup
          options: {
            placeholder: 'eg: matterific.users, matterific.entities',
            hint: 'The name of the registered store to use for the lookup',
            prependInnerIcon: 'mdi-database'
          }
        },
        {
          name: 'context',
          label: 'Context',
          control: 'text',
          options: {
            hint: 'The name of the property i nthe store to use as the context for the lookup',
            prependInnerIcon: 'mdi-database-arrow-right'
          }
        }
      ]
    }
  }
};
