<template>
  <v-window
    v-if="flow"
    :model-value="activeState?.name"
  >
    <v-window-item
      v-for="(state, name) in flow"
      :key="name"
      :value="name"
    >
      <component
        :is="state.meta.Component"
        :key="machineState.context.key"
        v-bind="state.meta.props(machineState.context.props)"
        :disabled="isProcessing"
        @action="process"
        @cancel="process"
      />
    </v-window-item>
  </v-window>

  <mtf-debug
    v-if="isDebugging"
    title="Debug Flow"
    :processing="isProcessing"
    :model="activeState"
    :state="machineState"
  />
</template>

<script>
import { computed } from 'vue';
import { useSelector } from '@xstate/vue';
import { isObject, get, delay } from 'lodash-es';
import { useMatterificFlowMachine } from '#imports';

export default defineComponent({
  name: 'MtfFlow',
  inheritAttrs: false,
  customOptions: {},
  props: {
    flow: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props) {
    // -------- MACHINE/ENTITY
    const { state, service, send } = useMatterificFlowMachine(props.flow);

    // -------- UI

    const activeState = computed(() => get(props.flow, state.value.value));

    // -------- STATUS

    const isDebugging = useSelector(service, (state) => state.context.debug);

    const isProcessing = computed(() => ['processing'].some(state.value.matches));

    // -------- METHODS

    function process({ command, context, event, wait }) {
      if (!command) return;
      if (!isObject(context)) {
        context = {};
      }
      delay(() => {
        send(command, { context, event });
      }, wait || 0);
    }

    // -------- DEBUG

    return {
      // --- ui
      activeState,
      // --- state
      machineState: state,
      // --- status
      isProcessing,
      isDebugging,
      // --- methods
      process
    };
  }
});
</script>
