<template>
  <client-only>
    <v-card
      v-if="!isLoggedIn"
      :disabled="isLoading"
      variant="flat"
      v-bind="$attrs"
    >
      <v-form
        v-model="isValid"
        class="mx-2"
        @submit.prevent="doAction"
      >
        <div class="my-2">
          <v-text-field
            v-model.trim="model.email"
            :rules="rules.email"
            autocomplete="email"
            autofocus
            hint="This must be active as we will send a verification email to this address."
            label="Email"
            name="email"
            prepend-inner-icon="mdi-at"
            required
            type="email"
          />
        </div>

        <div
          v-if="!forgot"
          class="my-2"
        >
          <v-text-field
            v-model.trim="model.password"
            :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="current-password"
            :rules="rules.password"
            :type="showPassword ? 'text' : 'password'"
            hint="This should be a complex password with at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character. This is not enforced, but it is recommended."
            label="Password"
            prepend-inner-icon="mdi-lock"
            @click:append-inner="showPassword = !showPassword"
          />
        </div>
      </v-form>

      <v-card-actions>
        <v-btn
          type="submit"
          variant="flat"
          color="primary"
          append-icon="mdi-chevron-right"
          :disabled="!isValid || isLoading"
          @click="doAction"
        >
          {{ forgot ? 'Send reset email' : 'Continue' }}
        </v-btn>

        <v-btn
          v-if="!forgot"
          variant="text"
          size="x-small"
          :disabled="!isValid"
          :loading="isLoading"
          @click="forgot = true"
        >
          Forgot Password?
        </v-btn>

        <v-btn
          v-if="forgot"
          variant="text"
          size="x-small"
          :disabled="isLoading"
          @click="forgot = false"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </client-only>
</template>

<script>
import { useMatterificAuthStore } from '../store';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'MtfAuthForm',
  inheritAttrs: true,
  customOptions: {},
  props: {
    icons: { type: [Boolean], default: false },
    actionIn: { type: [String, Boolean], default: 'Sign In' }, // Primary action is Sign In
    actionOut: { type: [String, Boolean], default: 'Sign Out' }
  },

  setup() {
    const store = useMatterificAuthStore();
    const { isLoading, isLoggedIn } = storeToRefs(store);

    const rules = {
      email: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      password: [
        (v) => !!v || 'Password is required'
        // (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ]
    };

    const isValid = ref(false);
    const showPassword = ref(false);
    const forgot = ref(false);

    const model = ref({
      email: null,
      password: null
    });

    function doAction() {
      const data = toRaw(unref(model));
      if (forgot.value) {
        store.forgotPassword(data).then(() => (forgot.value = false));
      } else {
        store.signIn('email', data);
      }
    }

    return {
      rules,
      model,
      isLoading,
      isLoggedIn,
      isValid,
      showPassword,
      doAction,
      forgot
    };
  }
});
</script>
