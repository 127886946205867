import { createMachine, assign } from 'xstate';
import api from '../services'; // this is not a composable so we can reference it directly
import { first, reject } from 'lodash-es';

export default createMachine(
  {
    id: 'matterific.summons',
    predictableActionArguments: true,
    preserveActionOrder: true,
    initial: 'pending',

    context: {
      active: null,
      items: []
    },

    states: {
      pending: {
        invoke: {
          id: 'queue',
          src: 'queue',
          onDone: { target: 'active', actions: ['setActive'] },
          onError: { target: 'available' }
        }
      },

      active: {
        invoke: {
          id: 'activeItem',
          src: (context) => context.active,
          autoForward: true,
          // data: (context) => ({}),
          onDone: {
            target: 'dismissed',
            actions: ['clearActive']
          }
          // onError: { target: 'dismissed', actions: ['clearActive',] }
        },
        on: {
          // valid: { target: 'active' },
          // invalid: { target: 'active.invalid' }
        }
      },

      dismissed: {
        // this is a Transient state, so it will be exited immediately
        //  we need it so that we can react to the transition from the calling process/machine
        always: [{ target: 'pending' }]
      },
      available: {}
    },

    on: {
      add: {
        target: 'pending',
        actions: ['add']
        // cond: (context, {data}) => !!data.text // todo : proper validation
      },
      clear: {
        target: 'pending',
        actions: ['clear']
      }
    }
  },
  {
    actions: {
      clear: assign({ items: [] }), // put Firebase auth object on context

      add: assign({
        items: (context, { data }) => {
          context.items ??= []; // make sure we have an array
          return context.items.concat(api.createItem(data));
        }
      }),

      setActive: assign({ active: (context, { data }) => data }), // put Firebase auth object on context
      clearActive: assign({
        items: (context) => reject(context.items, ['context.id', context.active.context.id]),
        active: null
      })
    },

    services: {
      queue: (context) =>
        new Promise((resolve, reject) =>
          context.items?.length ? resolve(first(context.items)) : reject()
        )
    }
  }
);
