<script>
import { defineComponent } from 'vue';
import { useMatterificEntryMachine } from '#imports';
import { omit } from 'lodash-es';
import MtfManageEntity from '@matterific.entities/views/ManageEntity.vue';

const modifiedMtfManageEntity = omit(MtfManageEntity, 'props.entity');
const entity = 'form';

export default defineComponent({
  name: 'MtfForm',
  extends: modifiedMtfManageEntity,
  setup(props) {
    return {
      machine: useMatterificEntryMachine(entity, props.parent, props)
    };
  }
});
</script>
