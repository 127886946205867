<template>
  <client-only>
    <!-- Open Sign I Drawer -->
    <v-btn
      v-if="!isLoggedIn"
      color="primary"
      variant="flat"
      prepend-icon="mdi-login"
      :disabled="modelValue || isLoading"
      v-bind="$attrs"
      @click="openAuth = true"
    >
      Sign In
    </v-btn>

    <!-- SignOut -->
    <v-btn
      v-if="actionOut && isLoggedIn"
      v-bind="$attrs"
      :loading="isLoading"
      :prepend-icon="`mdi-logout`"
      class="mx-1"
      @click="doSignOut"
    >
      {{ actionOut }}
    </v-btn>
  </client-only>
</template>

<script>
import { useMatterificAuthStore } from '../store';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'MtfAuthTrigger',
  inheritAttrs: false,
  customOptions: {},
  props: {
    modelValue: Boolean,
    actionIn: { type: [String, Boolean], default: 'Sign In' }, // Primary action is Sign In
    actionOut: { type: [String, Boolean], default: 'Sign Out' }
  },

  setup() {
    const store = useMatterificAuthStore();
    const { isLoading, isLoggedIn, providers, openAuth } = storeToRefs(store);

    return {
      openAuth,
      doSignOut: store.signOut,
      isLoading,
      isLoggedIn,
      providers
    };
  }
});
</script>
