<template>
  <v-app-bar
    id="app-header"
    v-bind="safeAttrs"
    :key="timestamp"
    class="pl-4 pr-8"
  >
    <template #prepend>
      <mtf-link
        to="/"
        class="logo py-1 d-block"
        :class="[{ 'd-sr-only': !branding }]"
      >
        <mtf-icon
          name="logo"
          filled
        />
        <span
          v-if="companyName"
          class="d-sr-only"
        >
          {{ companyName }}
        </span>
      </mtf-link>
    </template>

    <template #append>
      <slot name="search" />

      <!-- Primary  Nav items -->
      <slot name="menuPrimary">
        <!--LOADING  -->
        <v-progress-circular
          v-if="loading"
          :color="color"
          indeterminate
          size="x-small"
          width="1"
        />

        <mtf-menu
          v-if="!loading && hasPrimaryMenu"
          class="d-flex flex-wrap justify-center justify-md-end"
          :class="`text-${color}`"
          :items="menuPrimary.items"
          :conditions="menuPrimary?.conditions"
          v-bind="safePrimaryMenuAttrs"
        />
      </slot>

      <slot name="actions">
        <mtf-auth-trigger
          v-if="hasAccount"
          class="ml-2"
          :icons="false"
          :x-small="mobile"
          :action-in="actionIn"
          :action-out="actionOut"
        />
      </slot>
    </template>
  </v-app-bar>
</template>

<script>
import { useAppConfig } from '#imports';

import { storeToRefs } from 'pinia';
import { useMatterificStore } from '@matterific/store';
import { useMatterificAuthStore } from '@matterific.auth/store';
import { useDisplay } from 'vuetify';

import MtfLink from '@matterific/components/Link.vue';
import MtfIcon from '@matterific/components/Icon.vue';

export default defineComponent({
  name: 'MtfHeader',
  components: {
    MtfLink,
    MtfIcon
  },
  inheritAttrs: false,
  customOptions: {},
  props: {
    company: String,
    reactiveText: { type: Boolean, default: false },
    background: String,
    color: String,
    hasAccount: { type: Boolean, default: true },
    branding: { type: Boolean, default: true },
    hasProfile: { type: Boolean, default: true },
    actionIn: { type: String, default: 'Sign In' }, // Primary action is Sign In
    actionOut: { type: String, default: 'Sign Out' },
    //
    loading: { type: Boolean, default: false },
    menuPrimary: { type: [Object, String] }
  },
  setup(props, { slots, attrs }) {
    // --------
    // PRIVATE

    const { mobile } = useDisplay();

    const config = useAppConfig();

    // We need to include this to ensure the store is loaded and the auth button is reactive
    const authStore = useMatterificAuthStore(); // eslint-disable-line @typescript-eslint/no-unused-vars

    const timestamp = ref(Date.now());

    const store = useMatterificStore();
    const { settings } = storeToRefs(store);

    const shinethrough = computed(() => {
      return settings.value?.header?.shinethrough;
    });

    // --------
    const { company, background, color, menuPrimary, reactiveText } = toRefs(props);

    const companyName = computed(() => company.value || config?.title);

    const safeBackground = computed(() => (shinethrough.value ? 'transparent' : background.value));

    const safeAttrs = computed(() => ({
      ...attrs,
      app: !mobile.value,
      color: safeBackground.value,
      hideOnScroll: !mobile.value,
      absolute: true,
      flat: true,
      // fixed: true
      class: ['layer-hid', `text-${color.value}`, { 'text-reactive': reactiveText.value }]
    }));

    // --------
    // MENUS
    const hasPrimaryMenu = computed(() => !!slots.primary || !!menuPrimary.value?.items?.length);
    const safePrimaryMenuAttrs = computed(() => ({
      ...menuPrimary.value.settings,
      density: 'compact',
      bgColor: menuPrimary.value?.settings?.background || safeBackground.value,
      color: menuPrimary.value?.settings?.color || color.value,
      class: ['px-2', `text-${menuPrimary.value?.settings?.color}`],
      rounded: true
    }));

    return {
      timestamp,
      mobile,
      companyName,
      safeAttrs,
      safeBackground,
      shinethrough,
      // ---
      safePrimaryMenuAttrs,
      hasPrimaryMenu
      // ---
    };
  },
  mounted() {
    this.$nextTick(() => {
      const newTimestamp = Date.now();
      // console.log('header mounted, refreshing ui with timestamp', { timestamp: this.timestamp, newTimestamp });
      this.timestamp = newTimestamp;
    });
  }
});
</script>

<style lang="scss">
.v-app-bar.v-toolbar {
  .text-reactive {
    color: white !important; //necessary for contrast
    mix-blend-mode: difference;
  }

  .logo {
    color: currentColor;
  }

  .logo,
  .icon {
    height: 100%;
    max-height: 60px !important;
  }
}
</style>
