import { startCase } from 'lodash-es';
import pluralize from 'pluralize';
// import Fields from '@matterific.forms/matter/partials/fields';

export default () => {
  const entity = 'workflow';

  const config = {
    name: entity,
    color: 'accent',
    icon: 'mdi-list-box',
    title: startCase(entity),
    description: 'A workflow',
    note: 'A "workflow" note',
    // ------------------------------------
    singular: startCase(entity),
    plural: pluralize(startCase(entity)),
    // ------------------------------------
    sort: [['title', 'asc']],
    filter: {},
    count: 'entries'
  };

  const schema = {
    // $schema: 'https://json-schema.org/draft/2020-12/schema',
    // $id: '/matter/form',
    type: 'object',
    title: 'Workflow',
    description: 'A workflow',
    note: 'This is the blueprint for a workflow',
    required: ['name', 'title', 'description'],
    properties: {
      name: {
        type: 'string',
        pattern: '^[a-z,0-9]+(?:-[a-z,0-9]+)*$',
        isNotEmpty: true
      },
      title: {
        type: 'string',
        isNotEmpty: true
      },
      description: {
        type: 'string',
        maxLength: 80
      },

      // *** FORM LIMITS ***
      // Limit will determine if there is:
      // a limit to how many times a user can submit the form,
      // or how many times the form can be submitted in total
      // limit: {
      //   type: ['object', 'null'],
      //   required: ['type', 'value'],
      //   properties: {
      //     type: {
      //       type: ['string', 'null'],
      //       enum: ['user', 'total', null]
      //     },
      //     value: {
      //       type: ['number', 'null'],
      //       minimum: 0
      //     }
      //     // todo select status that count towards the limit
      //   }
      // },

      // *** FORM FIELDS ***
      // What fields to be rendered in the form'
      // ...Fields.schema,

      // *** POST ENTRY FIELDS ***
      // What will happen AFTER the user has successfully submitted the form
      // should be in its own group

      // show messages after entry
      messages: {
        type: 'object',
        isNotEmpty: true,
        required: ['entry', 'closed', 'unavailable'],
        properties: {
          entry: {
            type: 'string',
            maxLength: 280,
            default: 'Thank you for your entry. We will be in touch shortly.',
            isNotEmpty: true
          },
          closed: {
            type: 'string',
            maxLength: 280,
            default: 'We have received your entry and will review it shortly',
            isNotEmpty: true
          },
          unavailable: {
            type: 'object',
            required: ['conditions', 'permissions'],

            properties: {
              conditions: {
                type: 'string',
                maxLength: 280,
                default:
                  'You do not meet the requirements to interact with this form, if you think this is an error please contact us',
                isNotEmpty: true
              },
              permissions: {
                type: 'string',
                maxLength: 280,
                default:
                  'You do not have permission to interact with this form, if you think this is an error please contact us',
                isNotEmpty: true
              }
            }
          }
        }
      },

      // do actions after entry
      actions: {
        type: 'object',
        properties: {
          redirect: {
            type: 'string',
            format: 'uri-reference',
            description: 'where to redirect after entry, if empty will stay on the same page',
            examples: ['/thank-you']
          },
          webhook: {
            type: 'string',
            format: 'uri',
            description: 'webhook to call after entry, will pass the entry data as a JSON object',
            examples: ['https://www.zapier.com/dfgrtrtfgcgf']
          }
        }
      }
    }
  };

  const uischema = {
    form: {
      fields: [
        {
          name: 'name',
          label: 'Name',
          control: 'text',
          options: {
            autofocus: true,
            hint: 'This is the Internal name that will be used to identify the form, must be lowercase and no spaces ( use hyphens instead )',
            prependInnerIcon: 'mdi-text'
          }
        },
        {
          name: 'title',
          label: 'Title',
          control: 'text',
          options: {
            hint: 'The visible title of the form',
            prependInnerIcon: 'mdi-format-title'
          }
        },
        {
          name: 'description',
          label: 'Description',
          control: 'text',
          options: {
            prependInnerIcon: 'mdi-text-short'
          }
        },
        {
          name: 'note',
          label: 'Note',
          control: 'textarea',
          options: {
            prependInnerIcon: 'mdi-text',
            rows: 1,
            autoGrow: true,
            clearable: true,
            counter: true
          }
        },

        {
          name: 'limit.type',
          label: 'Limit Entries...',
          control: 'select',
          options: {
            prependInnerIcon: 'mdi-lock',
            items: [
              { value: 'user', text: 'by User' },
              { value: 'total', text: 'in Total' }
            ],
            clearable: true,
            multiple: false,
            searchable: false,
            hint: 'Limit the number of entries by user or in total'
          }
        },

        {
          name: 'limit.value',
          label: 'Limit Amount',
          control: 'number',
          options: {
            hint: 'The amount of active/pending entries to allow, 0 for unlimited',
            prependInnerIcon: 'mdi-counter',
            clearable: true,
            min: 0,
            step: 1
          }
        },

        // fields
        ...Fields.uischema.form.fields,

        // messages
        {
          name: 'messages.entry',
          label: 'Success Message',
          control: 'textarea',
          options: {
            hint: 'The message to show after entry to indicate everything went as planned',
            rows: 1,
            autoGrow: true,
            clearable: true,
            counter: true,
            prependInnerIcon: 'mdi-text-long'
          }
        },
        {
          name: 'messages.closed',
          label: 'Closed Message',
          control: 'textarea',
          options: {
            hint: 'The message to show when the form is closed',
            rows: 1,
            autoGrow: true,
            clearable: true,
            counter: true,
            prependInnerIcon: 'mdi-text-long'
          }
        },
        {
          name: 'messages.unavailable.conditions',
          label: 'Unavailable Conditions Message',
          control: 'textarea',
          options: {
            hint: 'The message to show when the conditions are not met',
            rows: 1,
            autoGrow: true,
            clearable: true,
            counter: true,
            prependInnerIcon: 'mdi-text-long'
          }
        },
        {
          name: 'messages.unavailable.permissions',
          label: 'Unavailable Permissions Message',
          control: 'textarea',
          options: {
            hint: 'The message to show when the permissions are not met',
            rows: 1,
            autoGrow: true,
            clearable: true,
            counter: true,
            prependInnerIcon: 'mdi-text-long'
          }
        },

        // actions
        {
          name: 'actions.redirect',
          label: 'Redirect',
          control: 'url',
          options: {
            hint: 'where to redirect after entry, if empty will stay on the same page',
            prependInnerIcon: 'mdi-link-variant'
          }
        },
        {
          name: 'actions.webhook',
          label: 'Webhook',
          control: 'url',
          options: {
            hint: 'webhook to call after entry, will pass the entry data as a JSON object',
            prependInnerIcon: 'mdi-webhook'
          }
        }
      ],
      actions: {
        cancel: {
          label: 'Back',
          prependIcon: 'mdi-chevron-left'
        },
        submit: {
          color: config.color
        }
      }
    },

    listings: {
      header: {
        color: config.color,
        filters: {},
        query: ['title', 'description', 'note'],
        actions: {
          add: {
            variant: 'tonal',
            label: `New ${config.singular}`,
            icon: 'mdi-plus',
            command: 'add'
          }
        },
        menuActions: {}
      },
      messages: {
        empty: {
          title: `No ${config.plural} found`,
          description: (context) =>
            context.isFiltered
              ? 'Try clearing any filters or search terms'
              : `Try adding your first ${config?.singular}`,
          note: config.note,
          color: config.color,
          icon: config.icon,
          actions: {
            add: {
              variant: 'tonal',
              label: `Add ${config.singular}`,
              icon: 'mdi-plus',
              command: 'add',
              conditions: [{ context: 'isFiltered', assert: false }]
            }
          }
        }

        // TODO:  messages for each of the actions
      },
      card: {
        size: 6,
        color: config.color,
        badges: {
          fields: {
            color: config.color,
            icon: 'mdi-list-box',
            content: (context) => context?.fields?.length || 0
          },
          entries: {
            color: config.color || 'info',
            icon: 'mdi-clipboard-check-multiple-outline',
            content: (context) => context.entries
            // conditions: [{ context: 'entries', operator: '>', assert: 0 },]
          }
        },
        actions: {
          edit: {
            label: 'Edit',
            icon: 'mdi-pencil',
            command: 'edit'
          },
          remove: {
            label: 'Delete',
            icon: 'mdi-delete',
            command: 'remove'
          },
          duplicate: {
            label: 'Duplicate',
            icon: 'mdi-content-copy',
            command: 'duplicate'
          },
          preview: {
            label: 'Preview',
            icon: 'mdi-eye',
            command: 'preview'
          },
          entries: {
            label: 'Entries',
            icon: 'mdi-playlist-check',
            command: 'entries'
          }
        }
      }
    }
  };

  const permissions = {
    auth: true,
    admin: [
      // can('manage', entity)
    ],
    member: [
      // can(['read','list','add','update','remove'],entity),
    ],
    guest: [
      // can do nothing...
    ]
  };

  const conditions = [];

  const computedProperties = {
    isNew: (context) => !context.id,
    hasEntries: (context) => !!context.entries?.length, // todo get entries count from service
    hasFields: (context) => !!context.fields?.length,
    sizeofEntries: (context) => context.entries?.length, // todo get entries count from service
    sizeofFields: (context) => context.fields?.length
  };

  return {
    config,
    schema,
    uischema,
    permissions,
    conditions,
    computedProperties
  };
};
