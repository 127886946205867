import { defineNuxtPlugin } from '#app';
import { useMatterificStore } from '@matterific/store';
import useMatter from './';
import useEntryMatter from './entry';

// eslint-disable-next-line no-unused-vars
export default defineNuxtPlugin((nuxtApp) => {
  // add each of our module matter definitions to the global matter object
  const matterific = useMatterificStore();
  matterific.defineMatter('Form', useMatter);
  matterific.defineMatter('FormEntry', useEntryMatter);
});
