<template>
  <v-navigation-drawer
    :model-value="showAuth"
    width="640"
    temporary
    app
    location="end"
    @update:model-value="(value) => (openAuth = value)"
  >
    <v-toolbar color="primary">
      <v-icon
        icon="mdi-login"
        end
      />
      <v-toolbar-title>
        {{ isLoading ? `Signing In...` : `Sign In with...` }}
      </v-toolbar-title>
      <v-tooltip
        text="Close Sign In"
        location="start"
        contained
      >
        <template #activator="{ props: tooltipProps }">
          <v-btn
            v-bind="tooltipProps"
            icon="mdi-close"
            variant="text"
            size="x-small"
            @click="openAuth = false"
          />
        </template>
      </v-tooltip>
    </v-toolbar>
    <v-progress-linear
      :active="isLoading"
      color="primary"
      indeterminate
    />

    <v-expand-transition
      appear
      mode="out-in"
    >
      <v-alert
        :text="error?.message"
        :type="error?.statusCode != 200 ? 'error' : 'success'"
        variant="tonal"
        density="compact"
        :model-value="isError"
        class="mb-4 w-100"
        :rounded="0"
      />
    </v-expand-transition>

    <div class="px-2">
      <v-list>
        <v-list-item
          v-for="provider in providers"
          :key="provider.name"
          :disabled="provider.disabled || isLoading"
          :prepend-icon="provider.icon"
          :title="provider.label"
          @click="provider.resolve()"
        />

        <v-divider inset />

        <v-list-item
          title="Your Email"
          prepend-icon="mdi-email-lock"
        />
      </v-list>

      <mtf-auth-form
        class="ml-16"
        :action-in="actionIn"
        :action-out="actionOut"
        :icons="icons"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
import { useMatterificAuthStore } from '../store';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'MtfAuthDrawer',
  components: {},
  inheritAttrs: true,
  customOptions: {},
  props: {
    icons: { type: [Boolean], default: false },
    actionIn: { type: [String, Boolean], default: 'Sign In' }, // Primary action is Sign In
    actionOut: { type: [String, Boolean], default: 'Sign Out' }
  },

  setup() {
    const store = useMatterificAuthStore();
    const { isLoggedIn, isLoading, providers, isError, error, user, openAuth } = storeToRefs(store);
    const showAuth = computed(() => openAuth.value && !isLoggedIn.value);

    return {
      openAuth,
      error,
      user,
      providers,
      isError,
      isLoading,
      isLoggedIn,
      showAuth
    };
  }
});
</script>
