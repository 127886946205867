/**
 * Set permissions for a guest
 *
 * @param  {object} context - context of the current user or request
 * @param  {object} AbilityBuilder - the casl ability instance
 */
function setPerms(context, { cannot }) {
  cannot('manage', 'all');
}

export default {
  name: 'guest',
  title: 'Guest',
  description: 'Unknow user, no access to resources',
  icon: 'mdi-account-question',
  color: 'grey',
  setPerms
};
