import { reduce, set } from 'lodash-es';
import { useValues } from './values';

export const useModelBuilder = (properties, context) =>
  reduce(
    properties,
    (result, prop, name) => {
      let value = null;

      if (prop.const) {
        value = prop.const;
      } else {
        switch (prop.type) {
          case 'array':
            value = useValues(prop.default, [], context);
            break;

          case 'object':
            value = useModelBuilder(prop.properties); // nb: this is recursive as we may have nested fields
            break;

          case 'boolean':
            value = useValues(prop.default, false, context);
            break;

          case 'number':
          case 'string':
          default:
            value = useValues(prop.default, null, context);
        }
      }

      set(result, name, value);
      return result;
    },
    {}
  );
