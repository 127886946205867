import { defineAsyncComponent } from 'vue';
import { minItems, maxItems } from '../rules';

// the component for this field
export const component = defineAsyncComponent(() => import('./FieldFlexible.vue'));

// the JSON Schema Definition for this field
export const schema = {
  type: 'object',
  required: [],
  properties: {
    '@type': { const: 'flexible' },
    options: {
      type: 'object',
      required: [],
      properties: {}
    },
    rules: {
      type: 'object',
      required: [],
      properties: {
        type: { const: 'array' },
        ...minItems.schema,
        ...maxItems.schema
      }
    }
  }
};

// the Ui Schema for this field
export const uischema = {
  value: 'flexible', // corresponds to the @type property in the JSON Schema
  title: 'Flexible',
  control: 'flexible',

  uischema: {
    fields: [minItems.uischema, minItems.uischema]
  }
};
