import { defineNuxtPlugin } from '#app/nuxt'
import { LazyMtfDebug, LazyMtfEmpty, LazyMtfError, LazyMtfFooter, LazyMtfForbidden, LazyMtfFormMachine, LazyMtfHeader, LazyMtfPage, LazyMtfRail, LazyMtfSidebar, LazyMtfAuthAction, LazyMtfAuthDrawer, LazyMtfAuthForm, LazyMtfAuthProfile, LazyMtfAuthTrigger, LazyMtfSection, LazyMtfSections, LazyMtfSubsections, LazyMtfManageEntities, LazyMtfManageEntity, LazyMtfFlow, LazyMtfForm, LazyMtfForms, LazyMtfList, LazyMtfMenu, LazyMtfMessages, LazyMtfSummons, LazyMtfWorkflow, LazyMtfWorkflows } from '#components'
const lazyGlobalComponents = [
  ["MtfDebug", LazyMtfDebug],
["MtfEmpty", LazyMtfEmpty],
["MtfError", LazyMtfError],
["MtfFooter", LazyMtfFooter],
["MtfForbidden", LazyMtfForbidden],
["MtfFormMachine", LazyMtfFormMachine],
["MtfHeader", LazyMtfHeader],
["MtfPage", LazyMtfPage],
["MtfRail", LazyMtfRail],
["MtfSidebar", LazyMtfSidebar],
["MtfAuthAction", LazyMtfAuthAction],
["MtfAuthDrawer", LazyMtfAuthDrawer],
["MtfAuthForm", LazyMtfAuthForm],
["MtfAuthProfile", LazyMtfAuthProfile],
["MtfAuthTrigger", LazyMtfAuthTrigger],
["MtfSection", LazyMtfSection],
["MtfSections", LazyMtfSections],
["MtfSubsections", LazyMtfSubsections],
["MtfManageEntities", LazyMtfManageEntities],
["MtfManageEntity", LazyMtfManageEntity],
["MtfFlow", LazyMtfFlow],
["MtfForm", LazyMtfForm],
["MtfForms", LazyMtfForms],
["MtfList", LazyMtfList],
["MtfMenu", LazyMtfMenu],
["MtfMessages", LazyMtfMessages],
["MtfSummons", LazyMtfSummons],
["MtfWorkflow", LazyMtfWorkflow],
["MtfWorkflows", LazyMtfWorkflows],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
