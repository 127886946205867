import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import { useRuntimeConfig } from '#imports';
import authServices from '../services';
import authMachine from '../machines';
import { useMachine, useSelector } from '@xstate/vue';
import { waitFor } from 'xstate/lib/waitFor';
import { useAbility } from '@casl/vue';
import { createAbilitiesFor, roles } from '../casl/ability';

import { isFunction, first, pickBy, get } from 'lodash-es';

export const useMatterificAuthStore = defineStore('matterific.auth', () => {
  const api = authServices();
  const ability = useAbility();
  const debug = !!useRuntimeConfig().public?.debug?.auth;

  const { send, state, service } = useMachine(authMachine, {
    context: { debug },
    services: pickBy(api, isFunction),
    devTools: debug
  });

  const user = useSelector(service, (state) => state.context?.user);
  const error = useSelector(service, (state) => state.context?.error);

  // update the abilities whenever the auth machine changes state
  service.onTransition((state) => {
    if (['authenticated', 'unauthenticated'].some(state.matches)) {
      const { rules } = createAbilitiesFor(state.context.user);
      ability.update(rules);
    }
  });

  // eslint-disable-next-line no-unused-vars
  const checkAuth = (context, event) => async (callback, onReceive) => {
    // firstly, we need to wait for the auth machine to be in a state that is not authenticating
    await waitFor(service, (state) => ['authenticated', 'unauthenticated'].some(state.matches));
    // then listen for any changes to the auth machine
    // and if we get a change to authenticated or unauthenticated
    // then we need to send a refresh event to the machine thats is subscribed to this service
    service.onTransition((state) => {
      if (['authenticated', 'unauthenticated'].some(state.matches)) {
        callback({ type: 'refresh' });
      }
    });

    // we also need to listen for any events that are sent to this service
    // and if we get a check event, then we need to
    // wait for the auth machine to be in a state that is not authenticating
    // then send a refresh event to the machine thats is subscribed to this service
    // so that the permissions and conditions can be checked
    onReceive(async (event) => {
      if (event.type == 'CHECK') {
        await waitFor(service, (state) => ['authenticated', 'unauthenticated'].some(state.matches));
        callback({ type: 'refresh' });
      }
    });

    return () => {
      // finally, the auth machine has been unsubscribed from this service
      // we dont need to do anything here
      // console.info('authStore', 'checkAuth', 'unsubscribed');
    };
  };

  function signIn(provider = 'email', data) {
    return new Promise((resolve, reject) => {
      // were using the auth store to handle our action to log in
      send('login', { provider, data });
      waitFor(service, (state) => ['authenticated', 'unauthenticated'].some(state.matches), {
        timeout: 20_000 // UP to 20 seconds in ms
      }).then(() => {
        if (state.value.matches('authenticated')) resolve();
        if (state.value.matches('unauthenticated.failure')) {
          reject(new Error(state.value.context?.error));
        }
      });
    });
  }

  function signOut() {
    return new Promise((resolve, reject) => {
      // were using the auth store to handle our action to log in

      send('logout');
      waitFor(service, (state) => ['unauthenticated'].some(state.matches)).then(() => {
        if (state.value.matches('unauthenticated.valid')) setTimeout(resolve, 800); // allow time for the loading  micro animation
        if (state.value.matches('unauthenticated.failure')) reject(new Error(state.context.error));
      });
    });
  }

  function forgotPassword(data) {
    return new Promise((resolve, reject) => {
      send('forgot', data);
      waitFor(service, (state) => ['authenticated', 'unauthenticated'].some(state.matches), {
        timeout: 20_000 // UP to 20 seconds in ms
      }).then(resolve, reject);
    });
  }

  // ----------------

  const providers = [
    {
      name: 'google',
      label: 'Your Google account',
      icon: 'mdi-google',
      resolve: (context) => signIn('google', context)
    }
  ];

  const openAuth = ref(false);

  // ----------------

  return {
    // state: computed(() => state.value),
    service,
    checkAuth,
    openAuth,
    // ----------------
    // getters
    user,
    error,
    providers: computed(() => providers),
    roles: computed(() => roles),
    onlyProvider: computed(() => (providers.length == 1 ? first(providers) : null)),
    // ----------------
    isLoading: computed(() =>
      ['authenticating', 'pending', 'signingIn', 'signingOut', 'resetting'].some(
        state.value.matches
      )
    ),
    isLoggedIn: computed(() => state.value.matches('authenticated')),
    isAdmin: computed(() => {
      const role = get(state.value, 'context.user.role');

      // HACK: if we ar ein dev mode, then we can use the email to check if the user is a developer
      // if (import.meta.env.DEV) {
      //   const isDeveloper = includes(
      //     state.value?.context?.auth?.email,
      //     '@thecollaboration.studio'
      //   );
      //   if (isDeveloper) return true;
      // }
      // retur nthe actual role
      return role?.name == 'admin';
    }),
    isError: computed(() => state.value.matches('unauthenticated.failure')),
    // ----------------
    // methods
    signIn,
    signOut,
    forgotPassword
  };
});
