<template>
  <client-only>
    <v-list class="h-100 w-100">
      <v-list-item
        v-if="current"
        class="py-2"
        three-line
      >
        <template #prepend>
          <v-avatar
            color="surface-variant"
            :src="current.photoURL"
            :text="Guest"
          />
        </template>

        <v-list-item-title> {{ current.title }} </v-list-item-title>
        <v-list-item-subtitle>{{ current.email }}</v-list-item-subtitle>
      </v-list-item>

      <v-divider inset />
      <slot />
    </v-list>
  </client-only>
</template>

<script>
export default defineComponent({
  name: 'MtfAuthProfile',
  props: {},
  data() {
    return {
      current: {
        id: 'guest',
        title: 'Guest User',
        email: ''
      }
    };
  },
  computed: {},
  methods: {}
});
</script>
