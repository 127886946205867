import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc } from 'firebase/firestore';
import { set, has, get } from 'lodash-es';

// TODO: add these back in when we need them and based on the config
import { getAnalytics } from 'firebase/analytics';
// import { getMessaging } from 'firebase/messaging';
// import { getAppCheck } from 'firebase/app-check';
// import { getPerformance } from 'firebase/performance';
// import { getStorage } from 'firebase/storage';
// import { getFunctions } from 'firebase/functions';
// import { getRemoteConfig } from 'firebase/remote-config';

// This is our global firebase object
const Firebase = {};

export default function useFirebase() {
  const runtimeConfig = useRuntimeConfig();
  const account = runtimeConfig.public.matterific?.account;
  const config = runtimeConfig.public.matterific?.database?.config;
  if (has(Firebase, 'app')) {
    // we have already initialized firebase
    return Firebase;
  }

  // TODO: Replace the following with your app's Firebase project configuration
  // See: https://support.google.com/firebase/answer/7015592
  if (!account) {
    const customError = new Error('Account Config Not Provided');
    customError.statusMessage = 'invalid';
    customError.statusCode = 400;
    throw customError;
  }

  if (!config) {
    const customError = new Error('Firebase Config Not Provided');
    customError.statusMessage = 'invalid';
    customError.statusCode = 400;
    throw customError;
  }

  set(Firebase, 'account', account);

  // Initialize Firebase
  set(Firebase, 'app', initializeApp(config));

  // Initialize Cloud Firestore and get a reference to the service
  set(Firebase, 'db', getFirestore(get(Firebase, 'app')));

  // get a reference to the account and check it exists
  set(Firebase, 'ref', doc(collection(get(Firebase, 'db'), 'accounts'), account));

  set(Firebase, 'usersRef', collection(get(Firebase, 'db'), 'users'));

  // const _doc = await getDoc(ref);
  // if (!_doc.exists()) {
  //   const customError = new Error(`Account: ${account} Does Not Exist!`);
  //   customError.statusMessage = 'not-found';
  //   customError.statusCode = 404;
  //   throw customError;
  // }
  if (import.meta.client) {
    set(Firebase, 'analytics', getAnalytics(get(Firebase, 'app')));
    set(Firebase, 'auth', getAuth(get(Firebase, 'app')));
    //set(Firebase, 'messaging', process.client && getMessaging(app),
    //set(Firebase, 'appcheck', process.client && getAppCheck(app),
    //set(Firebase, 'performance', process.client && getPerformance(app),
    //set(Firebase, 'storage', getStorage(app) ,
    //set(Firebase, 'functions', getFunctions(app) ,
    //set(Firebase, 'remoteConfig', getRemoteConfig(app) ,
  }
  // ------------------

  Firebase.userRef = () => {
    const uid = get(Firebase, 'auth.currentUser.uid');

    if (uid) {
      return doc(get(Firebase, 'usersRef'), uid);
    }
    return null;
  };

  return Firebase;
}
