// import { Todo } from '../models/Todo'
import { defineNuxtPlugin } from '#app';
import { abilitiesPlugin } from '@casl/vue';
import { createAbility } from './ability';

export default defineNuxtPlugin((nuxtApp) => {
  // by default rules are empty, as the user is not logged in
  // const rules = []; // for when we provide rules from the user
  const user = {}; // for when we predefine rules based on user
  nuxtApp.vueApp.use(abilitiesPlugin, createAbility(user));
});
