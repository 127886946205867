import { faker } from '@faker-js/faker';
import { random, nth, includes } from 'lodash-es';

export const generateData = (type) => {
  type ??= nth(['alert', 'banner', 'snackbar', 'dialog'], random(0, 3));
  const icon = nth(
    [
      'mdi-information',
      'mdi-alert-circle',
      'mdi-alert',
      'mdi-check',
      'mdi-passport-biometric',
      'mdi-account-circle'
    ],
    random(0, 5)
  );
  const color = nth(['info', 'error', 'warning', 'success', 'primary', 'secondary'], random(0, 5));

  const message = {
    type,
    props: {
      theme: random(0, 1) ? 'light' : 'dark',
      color,
      icon,
      persistent: 1
    },
    text: faker.lorem.paragraph()
  };
  if (includes(['snackbar', 'dialog', 'banner'], type)) {
    message.actions = {
      valid: {
        label: faker.lorem.word(),
        props: {
          prependIcon: icon,
          color: type == 'snackbar' ? null : color,
          variant: nth(['tonal', 'flat', 'outlined', 'elevated'], random(0, 3))
        },
        resolve: () =>
          new Promise((resolve, reject) => {
            if (random(0, 1)) {
              setTimeout(resolve, 800); // allow time for the loading  micro animation
            } else {
              setTimeout(() => reject(faker.lorem.sentence()), 800); // allow time for the loading  micro animation
            }
          })
      }
    };
  }

  return message;
};

const examples = [];

for (let index = 0; index < 10; index++) {
  examples.push(generateData());
}

export default examples;

// export default [
//   {
//     type: 'alert',
//     props: {
//       icon: '$success',
//       color: 'success'
//     },
//     text: faker.lorem.paragraph()
//   },
//   {
//     type: 'alert',
//     props: {
//       icon: '$info',
//       color: 'info'
//     },
//     text: faker.lorem.paragraph()
//   },
//   {
//     type: 'alert',
//     props: {
//       icon: '$error',
//       color: 'error'
//     },
//     text: faker.lorem.paragraph()
//   },
//   {
//     type: 'alert',
//     props: {
//       icon: '$warning',
//       color: 'warning'
//     },
//     text: faker.lorem.paragraph()
//   },
//   {
//     type: 'banner',
//     props: {
//       color: 'secondary',
//       theme: 'dark',
//       icon: 'mdi-passport-biometric',
//       sticky: true
//     },
//     text: faker.lorem.paragraph(),
//     actions:
//     {
//       claim: {
//         label: 'Claim your Mint Pass',
//         props: {
//           prependIcon: 'mdi-check',
//           color: 'secondary'
//         },
//         resolve: () => new Promise((resolve) => {
//           setTimeout(resolve, 800); // allow time for the loading  micro animation
//         })
//       }
//     }

//   },
//   {
//     type: 'banner',
//     props: {
//       color: 'success',
//       icon: 'mdi-check-circle'
//     },
//     text: faker.lorem.sentence()
//   },
//   {
//     type: 'banner',
//     props: {

//       color: 'info',
//       icon: 'mdi-information'
//     },
//     text: faker.lorem.sentence()
//   },
//   {
//     type: 'banner',
//     props: {
//       lines: 'two',
//       color: 'error',
//       icon: 'mdi-alert-circle'
//     },
//     text: faker.lorem.paragraph()
//   },
//   {
//     type: 'snackbar',
//     props: {
//       multiline: false,
//       color: 'info'
//     },
//     text: faker.lorem.sentence()
//   },
//   {
//     type: 'snackbar',
//     props: {
//       multiline: true,
//       color: 'error'
//     },
//     text: faker.lorem.paragraph()
//   },
//   {
//     type: 'snackbar',
//     props: {
//       persistent: true,
//       multiline: true,
//       color: 'success'
//     },
//     text: faker.lorem.paragraph()
//   },
//   {
//     type: 'dialog',
//     props: { width: '600px' },
//     text: faker.lorem.paragraphs(),
//     actions:
//     {
//       email: {
//         label: 'Email Link',
//         props: {
//           appendIcon: 'mdi-email',
//           color: 'info'
//         },
//         resolve: () => new Promise((resolve) => {
//           setTimeout(resolve, 800); // allow time for the loading  micro animation
//         })
//       }
//     }
//   },
// ];
