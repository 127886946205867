import { defineAsyncComponent } from 'vue';
import {
  autocomplete,
  autofocus,
  clearable,
  counter,
  hint,
  placeholder,
  multiple,
  chips,
  items
} from '../options';
import { minItems, maxItems } from '../rules';

// the component for this field
export const component = defineAsyncComponent(() => import('./FieldSelect.vue'));

// the JSON Schema Definition for this field
export const schema = {
  type: 'object',
  properties: {
    '@type': { const: 'select' },
    options: {
      type: 'object',
      required: ['items'],
      properties: {
        // --- first required fields
        ...items.schema,
        // --- then not required fields
        ...autofocus.schema,
        ...chips.schema,
        ...clearable.schema,
        ...hint.schema,
        ...multiple.schema,
        ...placeholder.schema
      }
    },
    rules: {
      type: 'object',
      required: [],
      properties: {
        type: { const: 'string' },
        ...minItems.schema,
        ...maxItems.schema
      }
    }
  }
};

// the Ui Schema for this field
export const uischema = {
  value: 'select', // corresponds to the @type property in the JSON Schema
  title: 'Dropdown Select',
  control: 'select',
  uischema: {
    fields: [
      autofocus.uischema,
      clearable.uischema,
      counter.uischema,
      placeholder.uischema,
      hint.uischema,
      autocomplete.uischema,
      minItems.uischema,
      maxItems.uischema,
      items.uischema,
      multiple.uischema
    ]
  }
};
