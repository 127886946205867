import { defineAsyncComponent } from 'vue';
import { hint } from '../options';

// the component for this field
export const component = defineAsyncComponent(() => import('./FieldCheckbox.vue'));

// the JSON Schema Definition for this field
export const schema = {
  type: 'object',
  required: [],
  properties: {
    '@type': { const: 'checkbox' },
    options: {
      type: 'object',
      required: [],
      properties: { ...hint.schema }
    },
    rules: {
      type: 'object',
      required: [],
      properties: {
        type: { const: 'boolean' }
      }
    }
  }
};

// the Ui Schema for this field
export const uischema = {
  value: 'checkbox', // corresponds to the @type property in the JSON Schema
  title: 'Checkbox',
  control: 'checkbox',

  uischema: {
    fields: [hint.uischema]
  }
};
