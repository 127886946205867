<template>
  <v-list v-if="hasMenu && allConditions">
    <mtf-nav-item
      v-for="(item, index) in items"
      :key="`navitem-${index}`"
      v-bind="item"
    />
  </v-list>
</template>

<script>
import MtfNavItem from '../components/NavItem.vue';
import { useCheckConditions } from '@matterific/utils';

export default defineComponent({
  name: 'MtfMenu',
  components: {
    MtfNavItem
  },
  props: {
    items: Array,
    conditions: Array
  },
  computed: {
    hasMenu() {
      return !!this.items?.length;
    },
    allConditions() {
      return useCheckConditions(this.conditions, this.items);
    }
  }
});
</script>
