import { map, slice, trimStart, trimEnd, words, camelCase, flow, upperFirst } from 'lodash-es';

export const usePrepend = (string, chars) => {
  const clean = trimStart(string, chars);
  return `${chars}${clean}`;
};

export const useInitials = (name, chars = 2) => {
  const safeNames = slice(words(name), 0, chars);
  return map(safeNames, (name) => name.charAt())
    .join('')
    .toUpperCase();
};

export const pascalCase = flow(camelCase, upperFirst);
