import { default as _404Y2dfZkz2IhMeta } from "/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/pages/404.vue?macro=true";
import { default as PageXyLlO5Nmj1Meta } from "/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/views/Page.vue?macro=true";
import { default as component_45stubWm99gVgwMFMeta } from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubWm99gVgwMF } from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "404___en",
    path: "/404",
    component: () => import("/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/pages/404.vue")
  },
  {
    name: "Page___en",
    path: "/:slug*",
    component: () => import("/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/views/Page.vue")
  },
  {
    name: component_45stubWm99gVgwMFMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubWm99gVgwMF
  },
  {
    name: component_45stubWm99gVgwMFMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubWm99gVgwMF
  },
  {
    name: component_45stubWm99gVgwMFMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubWm99gVgwMF
  }
]