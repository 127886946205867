<template>
  <nuxt-link
    v-if="!isExternalURL"
    :to="computedTo"
    v-bind="$attrs"
  >
    <slot>
      <span>{{ action }}</span>
    </slot>
  </nuxt-link>
  <a
    v-else
    :href="computedHref"
    :target="computedTarget"
    v-bind="$attrs"
  >
    <slot>
      <span>{{ action }}</span>
    </slot>
  </a>
</template>

<script>
export default defineComponent({
  name: 'MtfLink',
  props: {
    action: { type: String },
    href: { type: String },
    to: { type: [String, Object] }
  },
  computed: {
    computedHref() {
      return this.isExternalURL ? this.href : null;
    },
    computedTo() {
      return !this.isExternalURL ? this.to : null;
    },
    computedTarget() {
      return this.isExternalURL ? '_blank' : null;
    },

    isExternalURL() {
      try {
        return (!this.to && this.href) || new URL(this.to).origin !== location.origin;
      } catch {
        return false;
      }
    }
  }
});
</script>
