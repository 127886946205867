<template>
  <v-app>
    <mtf-summons />

    <mtf-rail
      v-bind="settings?.rail"
      :loading="settings.pending"
    />

    <mtf-sidebar
      v-bind="settings?.sidebar"
      :loading="settings.pending"
    />

    <mtf-header
      v-bind="settings?.header"
      :loading="settings.pending"
    />

    <v-main
      id="main"
      role="main"
      :class="['position-relative']"
    >
      <mtf-messages />

      <v-container
        fluid
        class="pa-0 justify-center align-start"
      >
        <mtf-error
          :description="message"
          :note="description"
          :status-code="statusCode"
        >
          <code>
            <pre>{{ data }}</pre>
          </code>
        </mtf-error>
      </v-container>

      <mtf-footer
        v-bind="settings?.footer"
        :loading="settings.pending"
      />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useMatterificStore } from '@matterific/store';
import { useMatterificAuthStore } from '@matterific.auth/store';

const timestamp = ref(Date.now());
const store = useMatterificStore();
const { settings } = storeToRefs(store);
useMatterificAuthStore(); //ensure the auth store is loaded

onMounted(async () => {
  // this is needed to ensure the page setting overrides are refreshed
  //  prevents a flash of the old settings
  await nextTick();
  const newTimestamp = Date.now();
  timestamp.value = newTimestamp;
});

const props = defineProps({
  error: Object
});

const { statusCode, message, description, data } = props.error;

// const handleError = () => clearError({ redirect: '/' });
</script>
