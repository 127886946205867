import { computed, watch, watchEffect, ref } from 'vue';
import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#imports';
import { useMatterificSummons } from '../composables/useMatterificSummons';
import api from '../services'; // this is not a composable so we can reference it directly
import examples, { generateData } from '../services/examples';
import { useSelector } from '@xstate/vue';
import { some, map } from 'lodash-es';

export const useMatterificSummonStore = defineStore('matterific.summons', () => {
  const config = useRuntimeConfig();
  const debug = config.public?.debug?.summon;

  const { send, state, service } = useMatterificSummons({
    items: debug ? map(examples, api.createItem) : []
  });

  const items = useSelector(service, (state) => state.context.items);
  const isActive = computed(() => state.value.matches('active'));
  const active = useSelector(service, (state) => state?.children?.activeItem);

  const summoned = ref(null);
  const meta = ref({
    available: null,
    invalid: null,
    processing: null,
    valid: null,
    failed: null,
    disabled: null
  });

  // ----------------
  //  Update meta data when state changes
  watchEffect(() => {
    if (active.value) {
      active.value.onTransition((state) => {
        summoned.value = state.context;
        meta.value.available = ['open.available'].some(state.matches);
        meta.value.invalid = ['open.available.invalid'].some(state.matches);
        meta.value.processing = ['open.resolving'].some(state.matches);
        meta.value.valid = ['open.available.valid'].some(state.matches);
        meta.value.failed = ['open.available.failure'].some(state.matches);
        meta.value.disabled = [
          'open.available.failure',
          'open.available.invalid',
          'open.resolving'
        ].some(state.matches);
      });
    } else {
      summoned.value = null;
      meta.value.available = null;
      meta.value.invalid = null;
      meta.value.processing = null;
      meta.value.valid = null;
      meta.value.failed = null;
      meta.value.disabled = null;
    }
  });

  // ----------------

  function isSummonedActive(id) {
    return id && summoned.value?.id == id;
  }

  function isSummonedTypeActive(type) {
    return type && summoned.value?.type == type;
  }

  function isQueued(id) {
    return some(items.value, ['context.id', id]);
  }

  function sendToActive(type, data) {
    if (!active.value || isQueued(data?.id)) return;
    send({ type, ...data });
  }

  function update(data) {
    sendToActive('update', { data });
  }

  function validate(value) {
    sendToActive('validate', { value });
  }

  function process(command) {
    sendToActive('resolve', { command });
  }

  function close() {
    sendToActive('dismiss');
  }

  function add(data) {
    // create a new item, which is a machine and add it to the queue
    // then we return an object with the data
    // and functions to wait for the item to be active or dismissed
    const item = api.createItem(data);
    send({ type: 'add', data: item });
    return {
      context: item.context,
      onActive: () => onActive(item.context.id),
      onDismissed: () => onDismissed(item.context.id)
    };
  }

  const onActive = (id) =>
    new Promise((resolve, reject) => {
      if (!isQueued(id)) reject(); //bail if our id is not in the queue

      const unwatch = watch(active, ({ state }) => {
        if (state?.context?.id == id) {
          unwatch(); //housekeeping
          resolve();
        }
      });
    });

  const onDismissed = (id) =>
    new Promise((resolve, reject) => {
      if (!isQueued(id)) reject(); //bail if our id is not in the queue
      // first we wait for the item to be active
      // then we wait for it to be dismissed, which would set the value to null
      onActive(id)
        .then(() => {
          const unwatch = watch(active, (value) => {
            if (!value) {
              unwatch(); //housekeeping
              resolve();
            }
          });
        })
        .catch(() => reject());
    });

  // --- admin/testing

  function clear() {
    send('clear');
  }

  async function create(type) {
    send({ type: 'add', data: generateData(type) });
  }

  return {
    send,
    meta,
    service,
    onActive,
    onDismissed,
    // ----------------
    summoned,
    // ----------------
    active,
    isActive,
    isQueued,
    isSummonedActive,
    isSummonedTypeActive,
    // ----------------
    update,
    validate,
    process,
    close,
    clear,
    add,
    create
  };
});
