import { assign, createMachine, sendParent } from 'xstate';
import { v4 as uuid } from 'uuid';
import { get, isFunction, isNil, isObject } from 'lodash-es';

// because we can spawn this machine from the entities machine,
// based on an active message,
// we need to be able to pass in the active message as the innitial context
export default (initialContext) =>
  createMachine(
    {
      id: 'matterific.message',
      initial: 'open', // we want to start by checking if user is logged in when page loads
      predictableActionArguments: true,

      context: {
        ...initialContext, // appy any context/props passed to us

        // safetyChecks, context we REQUIRE
        id: isNil(initialContext.id) ? uuid() : initialContext.id,
        actions: isObject(initialContext?.actions) ? initialContext?.actions : {}, // expect an object keyed by the event name

        // internal/private
        error: null
      },

      states: {
        open: {
          id: 'open',
          initial: 'available',
          states: {
            available: {
              id: 'available',
              initial: 'valid',
              states: {
                valid: {
                  on: {
                    resolve: {
                      target: '#open.resolving',
                      actions: ['clearError']
                    }
                  }
                },
                invalid: {},
                failure: {
                  // todo allow method/delay to clear the failure and move back into valid to allow retry
                }
              },
              on: {
                dismiss: {
                  target: '#closed',
                  actions: ['emitClosed']
                }
              }
            },

            resolving: {
              invoke: {
                id: 'resolving',
                src: 'resolveAction',
                onError: { target: 'available.failure', actions: ['setError'] },
                onDone: { target: '#closed' }
              }
            }
          }
        },

        closed: {
          id: 'closed',
          entry: ['emitClosed', 'log'],
          type: 'final'
        }
      }
    },
    {
      actions: {
        clearError: assign({ error: null }),
        setError: assign({
          error: (context, { data }) => data?.message || data || 'Unknown error'
        }),

        emitClosed: sendParent((context) => ({
          type: 'remove',
          id: context.id
        }))
      },
      services: {
        resolveAction: (context, { name }) => {
          return new Promise((resolve, reject) => {
            // 1. get the action's (derived from the event name) resolve function
            // 2 resolve the action's promise
            // return the response

            const action = get(context.actions, name, null);
            if (isFunction(action.resolve)) {
              action.resolve().then(resolve, reject);
            } else {
              const error = new Error(`Resolve Function for Action ${name} Not Found`);
              error.statusMessage = 'not-found';
              error.statusCode = 404;
              reject(error);
            }
          });
        }
      },
      guards: {}
    }
  );
