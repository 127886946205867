import { itemMachine } from '../machines/item';

import { v4 as uuid } from 'uuid';
import { defaultsDeep } from 'lodash-es';

const newModel = () => ({
  // --- Rquired Message Context
  id: uuid(),
  Component: null,
  actions: {},
  model: {},
  // --- Additional Context
  props: null,
  params: null,
  closeLabel: 'close',
  closeIcon: 'mdi-close'
});

export default {
  // expose managed state as return value, but our internals are frozen so they cant be mutated
  createItem: (data) => {
    if (data?.machine) return data; // we already have a machine, so just return it

    if (!data?.Component) return; // we need a component to render

    const model = defaultsDeep(
      data,
      newModel() // base model to ensure all props are hydrated
    );
    const machine = itemMachine(model);
    return machine;
  }
};
