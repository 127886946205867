import { startCase, keys, mapValues, find } from 'lodash-es';
import pluralize from 'pluralize';

import { useMatterificUserStore } from '@matterific.users/store';

export default () => {
  const entity = 'form-entry';

  // ensure our libraries/stores are loaded
  useMatterificUserStore();

  const config = {
    name: entity,
    color: 'accent',
    icon: 'mdi-list-box',
    // ------------------------------------
    title: startCase(entity),
    description:
      'Form entries in matterific represent are the instances of a form being captured by a user.',
    note: 'A `Form Entry` captures the information and associated data, that are defined in the form.',
    // ------------------------------------
    singular: startCase(entity),
    plural: pluralize(startCase(entity)),
    // ------------------------------------
    // Statuses can have only 1 initial, and will be applied to new entries
    // Statuses that are marked as default will be included as part of the default filter state
    statuses: {
      new: {
        name: 'new',
        label: 'New',
        color: 'success',
        icon: 'mdi-bell-ring',
        conditions: [{ context: 'status', operator: 'is empty' }],
        default: true,
        initial: true
      },
      active: {
        name: 'active',
        label: 'Start working on it',
        color: 'success',
        icon: 'mdi-play',
        conditions: [{ context: 'status', assert: 'new', operator: '==' }]
      },

      paused: {
        name: 'paused',
        label: 'Stop working on it, temporarily',
        color: 'warning',
        icon: 'mdi-pause',
        conditions: [{ context: 'status', assert: 'active', operator: '==' }]
      },

      closed: {
        name: 'closed',
        label: 'Finish working on it',
        color: 'grey',
        icon: 'mdi-check',
        conditions: [
          {
            context: 'status',
            assert: ['paused', 'active'],
            operator: 'in'
          }
        ]
      },
      rejected: {
        name: 'rejected',
        label: 'Cannot work on it',
        color: 'error',
        icon: 'mdi-alert',
        conditions: [
          {
            context: 'status',
            assert: ['paused', 'active'],
            operator: 'in'
          }
        ]
      },
      reactivate: {
        name: 'active',
        label: 'Continue working on it',
        color: 'success',
        icon: 'mdi-reload',
        conditions: [
          {
            context: 'status',
            assert: ['paused', 'closed', 'rejected'],
            operator: 'in'
          }
        ]
      }
    }
  };

  const schema = {
    // $schema: 'https://json-schema.org/draft/2020-12/schema',
    // $id: '/matter/form',
    type: 'object',
    // ------------------------------------
    // title: (context) => (!context?.isNew ? 'New Form' : `Edit ${context.title}`),
    title: 'Form Entry',
    description: 'A Form Entry is the instance of a form being captured by a user.',
    note: 'A `Form Entry` uses the matter defined in a parent Form, and then captures the information and triggers updates to the Pkgs status, attributes, and associated data, that are defined in the form.',

    // ------------------------------------
    required: ['status'],

    properties: {
      // core fields
      status: {
        type: 'string',
        enum: keys(config.statuses),
        default: find(config.statuses, 'default')?.name // Get the default status
      }
      // ------------------------------------
      // rest of the fields are inherited from the form matter
    }
  };

  const uischema = {
    form: {
      fields: [
        // defined in the form
      ],
      actions: {
        submit: {
          color: config.color
        }
      },
      messages: {
        // defined in the form
      }
    },

    listings: {
      header: {
        color: config.color,
        filters: {},
        query: ['title', 'description', 'note'],
        actions: {
          back: {
            variant: 'tonal',
            label: 'Back to Forms',
            icon: 'mdi-chevron-left',
            command: 'cancel'
          }
        },
        menuActions: {}
      },

      messages: {
        empty: {
          title: (context) => `No ${context?.config?.plural || config.plural} found`,
          description: (context) =>
            context.isFiltered
              ? 'Try clearing any filters or search terms'
              : 'Try adding your first',
          note: (context) => context?.config?.note || config.note,
          color: config.color,
          icon: config.icon,
          actions: {
            add: {
              label: 'Start',
              prependIcon: 'mdi-card-plus',
              command: 'add',
              variant: 'tonal',
              conditions: [{ context: 'isFiltered', assert: false }]
            }
          }
        }

        // TODO:  messages for each of the actions
      },

      card: {
        size: 4,
        color: config.color,
        badges: {
          status: {
            color: (context) => config.statuses[context?.status]?.color,
            content: (context) => startCase(context?.status)
          }
        },
        actions: {
          statusGroup: {
            label: 'Status',
            subheader: true
          },

          ...mapValues(config.statuses, (status) => ({
            ...status,
            command: 'transition',
            event: status
          }))
        }
      }
    }
  };

  const permissions = {
    auth: true
  };

  const conditions = [];

  const computedProperties = {
    isNew: (context) => !context.id
  };

  return {
    config,
    schema,
    uischema,
    permissions,
    conditions,
    computedProperties
  };
};
