import admin from './admin';
import member from './member';
import guest from './guest';

export const actions = ['manage', 'read', 'add', 'update', 'remove'];

export const subjects = ['all', 'users'];

export const fallback = guest;

export default {
  admin,
  member,
  guest
};
