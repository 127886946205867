<template>
  <v-navigation-drawer
    v-bind="safeAttrs"
    :key="timestamp"
    v-model="hasMenu"
  >
    <mtf-list
      nav
      density="compact"
      :items="menu.items"
      width="100%"
      :bg-color="safeBackground"
      :color="safeColor"
    />

    <template #append>
      <!--LOADING  -->
      <v-progress-linear
        :active="loading"
        :color="safeColor"
        indeterminate
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import mtfList from '@matterific.menus/views/List.vue';
import { useCheckConditions } from '@matterific/utils';

export default defineComponent({
  name: 'MtfRail',
  components: {
    mtfList
  },
  inheritAttrs: false,
  customOptions: {},
  props: {
    background: String,
    color: String,
    sticky: Boolean,
    menu: { type: [String, Object] },
    loading: { type: Boolean, default: false }
  },
  setup(props, { attrs }) {
    const hasMenu = computed(
      () => useCheckConditions(props.menu?.conditions, props.menu) && !!props.menu?.items?.length
    );
    // --------
    const safeColor = computed(() => props.color || props.menu?.settings?.color);
    const safeBackground = computed(() => props.background || props.menu?.settings?.background);
    const safeSticky = computed(() => props.sticky || props.menu?.settings?.sticky);
    const safeAttrs = computed(() => ({
      ...attrs,
      app: true,
      rail: true,
      permanent: true,
      color: safeBackground.value,
      expandOnHover: true,
      sticky: safeSticky.value,
      fixed: !safeSticky.value
    }));
    const timestamp = ref(Date.now());

    // --------
    return {
      safeColor,
      safeBackground,
      safeAttrs,
      hasMenu,
      timestamp
    };
  },
  mounted() {
    this.$nextTick(() => {
      const newTimestamp = Date.now();
      // console.log('rail mounted, refreshing ui with timestamp', { timestamp: this.timestamp, newTimestamp });
      this.timestamp = newTimestamp;
    });
  }
});
</script>
