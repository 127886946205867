import { defineAsyncComponent } from 'vue';
import { autocomplete, autofocus, hint, placeholder } from '../options';

// the component for this field
export const component = defineAsyncComponent(() => import('./FieldPlace.vue'));

// the JSON Schema Definition for this field
export const schema = {
  type: 'object',
  required: [],
  properties: {
    '@type': { const: 'place' },
    options: {
      type: 'object',
      required: [],
      properties: {
        ...autocomplete.schema,
        ...autofocus.schema,
        ...hint.schema,
        ...placeholder.schema
      }
    },
    rules: {
      type: 'object',
      required: [],
      properties: {
        type: { const: 'string' }
      }
    }
  }
};

// the Ui Schema for this field
export const uischema = {
  value: 'place', // corresponds to the @type property in the JSON Schema
  title: 'Place or Address',
  control: 'place',

  uischema: {
    fields: [autofocus.uischema, placeholder.uischema, hint.uischema, autocomplete.uischema]
  }
};
