<template>
  <client-only>
    <v-layout>
      <!-- Sign In -->
      <template v-if="!isLoggedIn">
        <v-btn
          v-if="onlyProvider"
          v-bind="$attrs"
          :loading="isLoading"
          :prepend-icon="icons ? onlyProvider.icon : null"
          @click="onlyProvider.resolve()"
        >
          {{ actionIn }}
        </v-btn>
        <v-menu
          v-else
          offset-y
          bottom
          left
          nudge-bottom="8"
          class="mx-1"
        >
          <template #activator="{ props: menuProps }">
            <v-btn
              v-bind="menuProps"
              :loading="isLoading"
              :prepend-icon="icons ? 'mdi-account-network' : null"
            >
              {{ actionIn }}
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item
                v-for="provider in providers"
                :key="provider.name"
                :disabled="provider.disabled"
                :prepend-icon="icons ? provider.icon : null"
                @click="provider.resolve()"
              >
                <v-list-item-title> {{ action }} with {{ provider.label }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>

      <!-- SignOut -->
      <v-btn
        v-if="actionOut && isLoggedIn"
        v-bind="$attrs"
        :loading="isLoading"
        :prepend-icon="icons ? `mdi-account-network-off` : null"
        @click="doSignOut"
      >
        {{ actionOut }}
      </v-btn>
    </v-layout>
  </client-only>
</template>

<script>
import { useMatterificAuthStore } from '@matterific.auth/store';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'MtfAuthAction',
  inheritAttrs: false,
  customOptions: {},
  props: {
    icons: { type: [Boolean], default: false },
    actionIn: { type: [String, Boolean], default: 'Sign In' }, // Primary action is Sign In
    actionOut: { type: [String, Boolean], default: 'Sign Out' }
  },

  setup() {
    const store = useMatterificAuthStore();
    const { isLoading, isLoggedIn, providers, onlyProvider } = storeToRefs(store);

    return {
      doSignOut: store.signOut,
      isLoading,
      isLoggedIn,
      providers,
      onlyProvider
    };
  }
});
</script>
