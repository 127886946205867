import { useMatterificFields } from '#imports';
import { values } from 'lodash-es';
const mtfFields = useMatterificFields();

export const schema = {
  fields: {
    type: 'array',
    uniqueItems: true,
    min: 1,
    // maxItems: 5,
    items: {
      type: 'object',
      required: ['name', 'label', 'control'],
      properties: {
        // *** CORE FIELDS ***
        // these are the properties that are common to all form fields
        name: {
          type: 'string',
          pattern: '^[a-z,0-9]+(?:-[a-z,0-9]+)*$',
          isNotEmpty: true
        },
        label: {
          type: 'string',
          maxLength: 80,
          isNotEmpty: true
        },
        required: {
          type: 'boolean'
        },
        control: {
          type: 'object',
          discriminator: { propertyName: '@type' },
          required: ['@type'],
          oneOf: values(mtfFields.schemas)
        }
      }
    }
  }
};

export const uischema = {
  form: {
    fields: [
      {
        name: 'fields',
        label: 'Fields',
        control: 'repeater',
        options: {
          prependInnerIcon: 'mdi-land-rows-horizontal',
          persistHint: true,
          hint: 'Quis consectetur officia nulla veniam duis adipisicing sunt Lorem minim enim sint.',
          rowLabel: (context) =>
            (context?.label || context?.name || `Field ${context?.index}`) +
            `${context.required ? ' *' : ''}`
        },
        uischema: {
          fields: [
            {
              name: 'name',
              label: 'Name',
              control: 'text',
              options: {
                autofocus: true,
                hint: 'This is the Internal name that will be used to identify the field, must be lowercase and no spaces ( use hyphens instead )',
                prependInnerIcon: 'mdi-text'
              }
            },
            {
              name: 'label',
              label: 'Label',
              control: 'text',
              options: {
                hint: 'This is what users will see on the form',
                prependInnerIcon: 'mdi-format-title'
              }
            },
            {
              name: 'required',
              label: 'Set this field to be Required and ensure the user must fill it in',
              control: 'checkbox',
              options: {
                prependIcon: 'mdi-asterisk'
              }
            },
            {
              name: 'control',
              label: 'Type of control',
              control: 'dependent', //  dependent = oneOf ; flexible = anyOf ; group = allOf
              options: {
                rowLabel: (context) => `${context} Control Details`,
                hint: 'This the type of input to get from the user when they fill in this field',
                prependInnerIcon: 'mdi-form-dropdown',
                items: values(mtfFields.uischemas)
              }
            }
          ]
        }
      }
    ]
  }
};

export default {
  schema,
  uischema
};
