import { defineAsyncComponent } from 'vue';

// the component for this field
export const component = defineAsyncComponent(() => import('./FieldGroup.vue'));

// the JSON Schema Definition for this field
export const schema = {
  type: 'object',
  required: [],
  properties: {
    '@type': { const: 'group' },
    options: {
      type: 'object',
      required: [],
      properties: {}
    },
    rules: {
      type: 'object',
      required: [],
      properties: {}
    }
  }
};

// the Ui Schema for this field
export const uischema = {
  value: 'group', // corresponds to the @type property in the JSON Schema
  title: 'Group',
  control: 'group',

  uischema: {
    fields: []
  }
};
