import { useRuntimeConfig } from '#imports';
import listingsMachine from '../machines/listings';
import { useMachine } from '@xstate/vue';

export const useMatterificMessages = (context = {}) => {
  context.debug = !!useRuntimeConfig().public?.debug?.message;

  return useMachine(listingsMachine, {
    context,
    devTools: context.debug
  });
};
