import { defineAsyncComponent } from 'vue';
import { autofocus, hint, placeholder } from '../options';
import { minLength, maxLength } from '../rules';
// the component for this field
export const component = defineAsyncComponent(() => import('./FieldPassword.vue'));

// the JSON Schema Definition for this field
export const schema = {
  type: 'object',
  required: [],
  properties: {
    '@type': { const: 'password' },
    options: {
      type: 'object',
      required: [],
      properties: {
        ...autofocus.schema,
        ...hint.schema,
        ...placeholder.schema
      }
    },
    rules: {
      type: 'object',
      required: [],
      properties: {
        type: { const: 'string' },
        ...minLength.schema,
        ...maxLength.schema
      }
    }
  }
};

// the Ui Schema for this field
export const uischema = {
  value: 'password', // corresponds to the @type property in the JSON Schema
  title: 'Password',
  control: 'password',

  uischema: {
    fields: [
      autofocus.uischema,
      placeholder.uischema,
      hint.uischema,
      minLength.uischema,
      maxLength.uischema
    ]
  }
};
