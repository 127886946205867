<template>
  <v-navigation-drawer
    v-bind="safeAttrs"
    :key="timestamp"
    v-model="hasMenu"
    :rail="!open"
    tag="aside"
  >
    <v-layout class="text-right justify-end py-2">
      <v-tooltip
        text="Toggle Menu"
        location="start"
      >
        <template #activator="{ props: tooltipProps }">
          <v-btn
            v-if="open"
            v-bind="tooltipProps"
            icon="mdi-close"
            variant="flat"
            :color="safeBackground"
            @click="open = false"
          />

          <v-btn
            v-if="!open"
            v-bind="tooltipProps"
            icon="mdi-menu"
            variant="flat"
            :color="safeBackground"
            @click="open = true"
          />
        </template>
      </v-tooltip>
    </v-layout>

    <template v-if="open">
      <mtf-list
        v-if="hasMenu"
        nav
        density="compact"
        :items="menu.items"
        width="100%"
        :bg-color="safeBackground"
        :color="safeColor"
      />
    </template>
    <template #append>
      <!--LOADING  -->
      <v-progress-linear
        :active="loading"
        :color="safeBackground"
        indeterminate
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import mtfList from '@matterific.menus/views/List.vue';
import { useCheckConditions } from '@matterific/utils';
import { useDisplay } from 'vuetify';

export default defineComponent({
  name: 'MtfSidebar',
  components: {
    mtfList
  },
  inheritAttrs: false,
  customOptions: {},
  props: {
    background: String,
    color: String,
    sticky: Boolean,
    menu: { type: [String, Object] },
    loading: { type: Boolean, default: false }
  },
  setup(props, { attrs }) {
    const { mobile } = useDisplay();
    const open = ref(false);
    // --------
    const hasMenu = computed(
      () => useCheckConditions(props.menu?.conditions, props.menu) && !!props.menu?.items?.length
    );
    // --------
    const safeColor = computed(() => props.color || props.menu?.settings?.color);
    const safeBackground = computed(() =>
      !open.value ? 'transparent' : props.background || props.menu?.settings?.background
    );
    const safeSticky = computed(() => props.sticky || props.menu?.settings?.sticky);
    const safeAttrs = computed(() => ({
      location: 'end',
      ...attrs,
      permanent: mobile.value,
      color: safeBackground.value,
      sticky: safeSticky.value,
      // --------
      // our closed rail props
      // permanent: !open.value,
      absolute: !open.value,
      temporary: !open.value,
      elevation: 0,
      floating: !open.value,
      scrim: false
      // border: true, rounded: true
    }));
    const timestamp = ref(Date.now());

    // --------
    return {
      safeColor,
      safeBackground,
      safeAttrs,
      open,
      hasMenu,
      timestamp
    };
  },
  mounted() {
    this.$nextTick(() => {
      const newTimestamp = Date.now();
      // console.log('sidebar mounted, refreshing ui with timestamp', { timestamp: this.timestamp, newTimestamp });
      this.timestamp = newTimestamp;
    });
  }
});
</script>
