/**
 * Matterific DB Services
 * Always return an objecy with the following properties
 * data, pending, error
 * This is the layer that handles the CRUD and other DB related operations
 * It should not be concerned with the UI or the state of the application or any machine state
 * It should onnly be used by the main service layer
 */
import { unref, ref } from 'vue';
import useFirebase from './useFirebase';

import {
  DocumentReference,
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocFromCache,
  getDocs,
  getDocsFromCache,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore';
import { pascalCase, useCleanValues } from '@matterific/utils';
import {
  isEmpty,
  camelCase,
  first,
  trim,
  map,
  defaultsDeep,
  isObject,
  isArray,
  forEach,
  set
} from 'lodash-es';

import pluralize from 'pluralize';

const defaultOptions = {
  maxDepth: 1
};

// This is our global firebase object
let Matterific;

export default (entity, parentRef) => {
  // only set it once
  Matterific ??= useFirebase();

  // --------------------------------------------
  // ensure we have a context ref, defaults to the account root of the db
  // but can be given a parent ref to a subcollection
  // Also, make our entity names/refs for db based on our naming conventions
  const contextRef = parentRef || Matterific.ref;
  const collectionName = pluralize(camelCase(entity));
  const entityRef = collection(contextRef, collectionName);
  const entityName = pascalCase(entity);

  // console.log('useMatterificDatabase', entityName);

  // --------------------------------------------

  /**
   * Get a single {entity} entity
   *
   * @param  {string } id - document id of the entity
   * @returns Promise
   */
  const fetch = (id) => {
    return useMatterificDocument(id, entityRef, entityName);
  };

  /**
   * Find a single {entity} entity
   *
   * @param  {array} filter? - Query parameters for filtering the collection
   * @returns Promise
   */
  const findOne = (filter) => {
    // console.log('Matterific', 'findOne', filter);
    return useMatterificCollection({ filter, limit: 1 }, entityRef, collectionName).then((data) => {
      // because we are using a limit, we return the first item
      return first(data);
    });
  };

  /**
   * Get a list of {entity} entries
   *
   * @param  {object} params? - parameters for filtering, ordering and additiona options for the collection query
   * @returns Promise
   */
  const find = (params) => {
    return useMatterificCollection(params, entityRef, collectionName);
  };

  const exists = async (id) => {
    const _docRef = getRef(id);
    const _doc = await getDoc(_docRef);
    return _doc.exists();
  };

  // --------------------------------------------

  /**
   * add/update an entity
   *
   * @param  {string | number} id - ID of entity to be saved
   * @param  {object} model - Model/data of entity to be saved
   * @returns Promise
   */
  const save = async (id, model) => {
    let error, response;
    let safeId = trim(unref(id || model?.id));
    if (safeId.toLowerCase() == 'new') safeId = null;
    const isNew = !safeId;

    if (isEmpty(model)) {
      error = new Error(`${entityName} ${id} Save Data Not Valid`);
      error.statusMessage = 'not-valid';
      error.statusCode = 400;
    } else {
      if (isNew) {
        response = await addDoc(entityRef.withConverter(entityConverter(entityName)), model).catch(
          (err) => (error = err)
        );
      } else {
        // otherwise check if it exists and set(update) it
        // we use set with Merge to ensure we dont overwrite any existing data
        // and aslo because setDoc triggers the converter, where updateDoc does not
        const _docRef = doc(entityRef, safeId).withConverter(entityConverter(entityName));
        response = await setDoc(_docRef, model, { merge: true }).catch((err) => (error = err));
      }
    }

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  };

  /**
   * add/update an entity's status
   * We keep a log of the transitions that have been processed
   * This is useful for auditing and debugging
   * We record :
   * the timestamp of the transition
   * the user that processed the transition
   * and any transaction ( by doc ref) that processed the transition
   *
   * @param  {string | number} id - ID of entity to be transitioned
   * @param  {object} from - the status to transition from
   * @param  {object} to - the status to transition to
   * @param  {object} ref - Optional reference to the transaction that processed the transition
   * @returns Promise
   */
  const updateStatus = async (id, to, from = null, ref = null) => {
    const currentUser = Matterific.userRef() || null;

    if (!id || !to) {
      const error = new Error(`Invalid Transition to: ${to} for document: ${id}`);
      error.statusMessage = 'invalid';
      error.statusCode = 400;
      throw error;
    }

    const _docRef = doc(entityRef, id);
    const _doc = await getDoc(_docRef);

    if (!_doc.exists()) {
      const error = new Error(`Document ${id} Not Found`);
      error.statusMessage = 'not-found';
      error.statusCode = 404;
      throw error;
    }

    // todo: some extra safety checks
    const data = {
      status: to,
      updated: serverTimestamp(),
      transitions: arrayUnion({
        from,
        to,
        on: Timestamp.fromDate(new Date()),
        with: ref,
        by: currentUser
      })
    };

    return updateDoc(_docRef, data);
  };

  /**
   * Remove an entity
   *
   * @param  {string | number} id - ID of entity to be deleted
   * @returns Promise
   */
  const remove = (id) => {
    const _docRef = getRef(id);
    return deleteDoc(_docRef);
  };

  // --------------------------------------------

  /**
   * Get a single {entity} entity reference
   *
   * @param  {string } id - document id of the entity
   * @returns Promise
   */
  const getRef = (id) => {
    return doc(entityRef, id);
  };

  // --------------------------------------------

  const services = {
    accountRef: Matterific.ref,
    usersRef: Matterific.usersRef,
    account: Matterific.account, // actual data or name?
    // ----------------
    entityName,
    entityRef,
    // ----------------
    getRef,
    // ----------------
    fetch,
    find,
    findOne,
    exists,
    // ----------------
    save,
    updateStatus,
    remove
  };

  return services;
};

// --------------------------------------------

const useMatterificDocument = (id, entityRef, entityName, params) => {
  params = defaultsDeep(params, defaultOptions);

  if (!entityRef || !id) {
    const customError = new Error(
      `${entityName || 'Document'} id:${id} or ref:${entityRef?.path} not Provided`
    );
    customError.statusMessage = 'invalid';
    customError.statusCode = 400;
    return Promise.reject(customError);
  }

  const docRef = doc(entityRef, id).withConverter(entityConverter(entityName));

  async function processDoc(snapshot) {
    if (snapshot.exists()) {
      const data = await handleReferences(snapshot.data(), 0, params.maxDepth);
      return data;
    } else {
      const customError = new Error(
        `${entityName || 'Document'}: ${id} Not Found in ${entityRef.path}`
      );
      customError.statusMessage = 'not-found';
      customError.statusCode = 404;
      throw customError;
    }
  }

  return getDocFromCache(docRef)
    .then(processDoc)
    .catch(() => getDoc(docRef).then(processDoc));
};

const useMatterificCollection = async (params, collectionRef, collectionName) => {
  params = defaultsDeep(params, defaultOptions);

  if (!collectionRef) {
    const customError = new Error(
      `${collectionName || 'Collection'} ref:${collectionRef?.path} not Provided`
    );
    customError.statusMessage = 'invalid';
    customError.statusCode = 400;
    return Promise.reject(customError);
  }

  const queryPrams = [];

  // filte must be an array of arrays
  if (params?.filter?.length)
    queryPrams.push(...map(params?.filter, (condition) => where(...condition)));

  // sort must be an array of arrays
  if (params?.sort?.length)
    queryPrams.push(...map(params?.sort, (condition) => orderBy(...condition)));

  // limit must be an integer greater than 0
  if (parseInt(params?.limit)) {
    queryPrams.push(limit(parseInt(params?.limit)));
  }

  // console.log('useMatterificCollection', collectionName, { queryPrams });
  const queryRef = query(
    collectionRef.withConverter(entityConverter(collectionName)),
    ...queryPrams
  );

  async function processDocs(querySnapshot) {
    let data = querySnapshot.docs.map((doc) => {
      let item = doc.data();
      if (params?.count) {
        set(item, params.count, collectionCount(doc.ref, params.count));
      }

      return item;
    });

    if (!data?.length) {
      const customError = new Error(`${collectionName || 'Collection'}: Not Found`);
      customError.statusMessage = 'not-found';
      customError.statusCode = 404;
      throw customError;
    }

    // Handle the DocumentReferences for each document
    for (let i = 0; i < data.length; i++) {
      data[i] = await handleReferences(data[i], 0, params?.maxDepth);
    }

    return data;
  }

  // try get fro mcache first, then from server
  return getDocsFromCache(queryRef)
    .then(processDocs)
    .catch(() => getDocs(queryRef).then(processDocs));
};

// we will eventually use entityName to set the CASL entity...once we implement CASL
// eslint-disable-next-line no-unused-vars
const entityConverter = (entityName) => {
  return {
    fromFirestore: (snapshot, options) => {
      if (!snapshot.exists()) return null;
      const data = Object.defineProperties(snapshot.data(options), {
        id: { value: snapshot.id },
        $path: { value: snapshot.ref.path },
        $meta: { value: snapshot.metadata },
        $ref: {
          get: () => snapshot.ref
        }
      });

      return data;
    },
    toFirestore(data) {
      const isNew = !data.id;
      data = useCleanValues(unref(data), false); // safetycheck, make sure we dont include any internal properties
      const currentUser = Matterific.userRef() || null;

      // add the created/updated timestamps
      if (isNew) {
        data.created = serverTimestamp();
        data.createdBy = currentUser;
      } else {
        data.updated = serverTimestamp();
        data.updatedBy = currentUser;
      }

      // todo:
      // Ensure the parent id, if any, is a document reference
      // That the parent document exists
      // And that the parent document is not a child (or a grandchild) of this document
      // if (data.parent && !isReference(data.parent)) {
      //   const _docRef = doc(entityRef.withConverter(matterificConverter()), data.parent);
      //   data.parent = _docRef;
      //   const _doc = await getDoc(_docRef);
      //   if (!_doc.exists()) {
      //     data.parent = null;
      //   }
      // }

      // if (data.children) {
      //   // Ensure ALL the children, if any, are a document reference
      //   // That each document exists
      //   // And that the child document is not a the current or parent document
      // }

      // this is where we can do our housekeeping for the data before it is saved
      return data;
    }
  };
};

// --------------------------------------------
// UTILS

// Cache for referenced documents
// const cache = new Map();

// Utility function to handle DocumentReference
// Helper function to handle DocumentReference
async function handleDocumentRef(value, depth, maxDepth) {
  let docData;

  // If maximum depth is reached, replace DocumentReference with its path
  if (depth >= maxDepth) {
    return value.path;
  }

  // if (cache.has(value.id)) {
  //   docData = cache.get(value.id);
  //  console.log(`Cache hit for document with id: ${value.id}`); // Log cache hit
  // } else {
  const docSnapshot = await getDoc(value);
  docData = docSnapshot.data();
  // cache.set(value.id, docData); // Add to cache
  // console.log(`Fetched document ref with id: ${value.id}`); // Log document fetch
  // }

  // Pass the results up the chain
  return handleReferences(docData, depth + 1, maxDepth).then(() => docData);
}

// Function to recursively handle properties which are DocumentReferences
async function handleReferences(data, depth = 0, maxDepth = 0) {
  const promises = [];

  if (isReference(data)) {
    promises.push(
      handleDocumentRef(
        data.withConverter(entityConverter(data?.parent?.id)),
        depth,
        maxDepth
      ).then((result) => {
        data = result;
      })
    );
  } else if (isArray(data) || isObject(data)) {
    forEach(data, (value, key) => {
      promises.push(
        handleReferences(value, depth, maxDepth).then((result) => {
          data[key] = result;
        })
      );
    });
  }

  // Wait for all promises to resolve
  await Promise.all(promises);
  return data;
}

/**
 * Get a count of {entity} entries/subcollection
 *
 * @param  {string} id? - document id of the entity
 * @param  {string} commectionName - name of the subcollection, defaults to 'entries'
 * @returns Promise
 */
const collectionCount = (docRef, collectionName = 'entries') => {
  const count = ref(0);
  const subQuerySource = query(collection(docRef, collectionName));
  getCountFromServer(subQuerySource).then((snapshot) => (count.value = snapshot.data().count));
  return count;
};

// --------------------------------------------
//PUBLIC UTILS
export function useMatterificSafeReference(value) {
  const { db } = useFirebase();
  if (isReference(value)) {
    return value;
  } else {
    return doc(db, value);
  }
}

/**
 * Check if a value is a Document Reference
 *
 * @param  {any} value? - value to be checked
 * @returns Boolean
 */
export const isReference = (value) => {
  return value instanceof DocumentReference;
};
