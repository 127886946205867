import { defineAsyncComponent } from 'vue';
import { hint } from '../options';

// the component for this field
export const component = defineAsyncComponent(() => import('./FieldDependent.vue'));

// the JSON Schema Definition for this field
export const schema = {
  type: 'object',
  required: [],
  properties: {
    '@type': { const: 'dependent' },
    options: {
      type: 'object',
      required: [],
      properties: {}
    },
    rules: {
      type: 'object',
      required: [],
      properties: {
        ...hint.schema
      }
    }
  }
};

// the Ui Schema for this field
export const uischema = {
  value: 'dependent', // corresponds to the @type property in the JSON Schema
  title: 'Dependent',
  control: 'dependent',
  options: {
    hint: 'This is a field that will render dependent fields based on the value of the choice, eg. if you choose Person it will render the Person fields and if you choose Place it will render the Place fields'
  },

  uischema: {
    type: { const: 'object' },
    fields: [hint.uischema]
  }
};
