import { defineNuxtPlugin } from '#app';
import { inspect } from '@xstate/inspect';
import { useRuntimeConfig } from '#imports';
import { omitBy, isEmpty } from 'lodash-es';

export default defineNuxtPlugin((/* nuxtApp */) => {
  const config = useRuntimeConfig();
  const debugging = omitBy(config.public.debug, (module) => !module);
  const isDebugging = !isEmpty(debugging);
  if (isDebugging) {
    inspect({
      iframe: false
    });
  }
});
