export const schema = {
  fields: {
    type: 'object',
    properties: {
      auth: {
        type: 'boolean',
        isNotEmpty: true
      },
      admin: {
        type: 'boolean',
        isNotEmpty: true
      }
    }
  }
};

export const uischema = {
  form: {
    fields: [
      // TODO
    ]
  }
};

export default {
  schema,
  uischema
};
