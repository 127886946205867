import { startCase } from 'lodash-es';
import pluralize from 'pluralize';

import Header from './partials/header';
import Footer from './partials/footer';
import Sidebar from './partials/sidebar';
import Conditions from './partials/conditions';
import Permissions from './partials/permissions';

export default () => {
  const entity = 'page';

  const config = {
    name: entity,
    color: '',
    icon: 'mdi-code-braces-box',
    title: startCase(entity),
    description: 'A core entity representing a web page or content item',
    note: 'An "Page" represents a single web page, it can be nested within other pages to create a hierarch. Pages are made up of sections, which are made up of blocks. Pages can be used to create a website, or to create a single content item such as a blog post, or a product page.',
    // ------------------------------------
    singular: startCase(entity),
    plural: pluralize(startCase(entity))
  };

  const schema = {
    // $schema: 'https://json-schema.org/draft/2020-12/schema',
    // $id: '/matter/content',
    type: 'object',
    // ------------------------------------
    required: ['path', 'title'],
    properties: {
      path: {
        title: 'Page Path',
        description: 'This is used to generate the url of the page.',
        type: 'string',
        isNotEmpty: true
      },
      title: {
        type: 'string',
        isNotEmpty: true
      },
      description: {
        type: ['string', 'null'],
        maxLength: 80
      },
      note: {
        type: ['string', 'null'],
        maxLength: 140
      },

      settings: {
        type: 'object',
        isNotEmpty: true,
        properties: {
          header: Header.schema,
          footer: Footer.schema,
          sidebar: Sidebar.schema,
          conditions: Conditions.schema,
          permisisons: Permissions.schema
        }
      },

      // *** SECTIONS ***
      above: {
        /* ...Sections.schema */
      },
      main: {
        /* ...Sections.schema */
      },
      aside: {
        /* ...Sections.schema */
      },
      below: {
        /* ...Sections.schema */
      }
    }
  };

  const uischema = {
    form: {
      fields: [
        //  TODO
      ],
      actions: {}
    },

    listings: {
      messages: {
        empty: {
          title: `No ${config.plural} found`,
          description: (context) =>
            context.isFiltered
              ? 'Try clearing any filters or search terms'
              : `Try adding your first ${config?.singular}`,
          note: config.note,
          color: config.color,
          icon: config.icon,
          actions: {
            add: {
              label: `Add ${config.singular}`,
              prependIcon: 'mdi-card-plus',
              command: 'add',
              variant: 'tonal',
              conditions: [{ context: 'isFiltered', assert: false }]
            }
          }
        }

        // TODO:  messages for each of the actions
      },
      card: {
        color: config.color,
        size: 4,
        badges: {
          contacts: {
            color: config.color,
            icon: 'mdi-card-account-details',
            content: (context) => context?.contacts?.length || 0
          }
        },
        actions: {
          edit: {
            label: 'Edit',
            icon: 'mdi-pencil',
            command: 'edit'
            // to: (context) => `${route.path}?id=${context.id}`
            // to: { name: 'state.edit', params: { id: '' } }
          },
          remove: {
            label: 'Delete',
            icon: 'mdi-delete',
            command: 'remove'
          },
          duplicate: {
            label: 'Duplicate',
            icon: 'mdi-content-copy',
            command: 'duplicate'
          }
        }
        // TODO: Add a "card" mode for the content
      }
    }
  };

  const permissions = {};

  const conditions = [];

  const computedProperties = {
    isNew: (context) => !context.id
  };

  return {
    config,
    schema,
    uischema,
    permissions,
    conditions,
    computedProperties
  };
};
