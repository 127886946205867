import itemMachine from '../machines/item';
import { spawn } from 'xstate';

import { v4 as uuid } from 'uuid';
import { defaultsDeep } from 'lodash-es';

const newModel = () => ({
  // --- Rquired Message Context
  id: uuid(),
  actions: {},
  // --- Additional Context
  props: {},
  closeLabel: 'close',
  closeIcon: 'mdi-close'
});

export default {
  // expose managed state as return value, but our internals are frozen so they cant be mutated
  hydrate: (data) =>
    defaultsDeep(
      data,
      newModel() // base model to ensure all props are hydrated
    ),
  useActor: (context) => spawn(itemMachine(context), `message-${context.id}`)
};
