import { defineAsyncComponent } from 'vue';
import { autocomplete, autofocus, hint, placeholder } from '../options';

// the component for this field
export const component = defineAsyncComponent(() => import('./FieldPhone.vue'));

// the JSON Schema Definition for this field
export const schema = {
  type: 'object',
  required: [],
  properties: {
    '@type': { const: 'phone' },
    options: {
      type: 'object',
      required: [],
      properties: {
        ...autocomplete.schema,
        ...autofocus.schema,
        ...hint.schema,
        ...placeholder.schema
      }
    },
    rules: {
      type: 'object',
      required: [],
      properties: {
        type: { const: 'object' },
        isPhoneNumber: { const: true }
        // properties: {
        //   number: {
        //     type: { const: 'string' },
        //   },
        //   countryCode: {
        //     type: { const: 'string' }
        //   },
        //   countryCallingCode: {
        //     type: { const: 'string' }
        //   },
        //   national: {
        //     type: { const: 'string' }
        //   },
        //   uri: {
        //     type: { const: 'string' },
        //     format: { const: 'uri' }
        //   },
        //   whatsappUri: {
        //     type: { const: 'string' },
        //     format: { const: 'uri' }
        //   }

        // }
      }
    }
  }
};

// the Ui Schema for this field
export const uischema = {
  value: 'phone', // corresponds to the @type property in the JSON Schema
  title: 'Phone',
  control: 'phone',

  uischema: {
    fields: [autofocus.uischema, placeholder.uischema, hint.uischema, autocomplete.uischema]
  }
};
