import { computed } from 'vue';
import { defineStore } from 'pinia';
import { useRuntimeConfig, useMatterificMessages } from '#imports';
import examples, { generateData } from './examples';
import { useActor, useSelector } from '@xstate/vue';
import { some } from 'lodash-es';

export const useMatterificMessageStore = defineStore('matterific.messages', () => {
  const config = useRuntimeConfig();
  const { send, state, service } = useMatterificMessages({
    items: config.public.matterific?.debug?.messages ? examples : null
  });

  const activeRef = computed(() => {
    if (!state.value?.context?.activeRef) return null;
    const activeRef = useActor(state.value.context.activeRef);
    return activeRef;
  });

  const items = useSelector(service, (state) => state.context.items);

  const isActive = computed(() => state.value.matches('active'));
  const isInvalid = computed(() => activeRef.value?.state.value.matches('open.available.invalid'));
  const isLoading = computed(() => activeRef.value?.state.value.matches('open.resolving'));
  const hasFailed = computed(() => activeRef.value?.state.value.matches('open.available.failure'));

  const message = computed(() => activeRef.value?.state.value.context);

  function isMessageActive(id) {
    return id && message.value.id == id;
  }

  function isMessageTypeActive(type) {
    return type && message.value?.type == type;
  }

  function isQueued(id) {
    return some(items.value, ['id', id]);
  }

  function sendToActive(type, data) {
    if (!activeRef.value || isQueued(data?.id)) return;
    activeRef.value.send({ type, ...data });
  }

  function update(data) {
    sendToActive('update', { data });
  }

  function validate(value) {
    sendToActive('validate', { value });
  }

  function process(command) {
    sendToActive('resolve', { command });
  }

  function close() {
    sendToActive('dismiss');
  }

  // --- admin/testing

  function clear() {
    send('clear');
  }

  function add(type) {
    send('add', generateData(type));
  }

  return {
    send,
    state: computed(() => state.value),
    service,
    // ----------------
    message,
    // ----------------
    activeRef,
    isQueued,
    isMessageActive,
    isMessageTypeActive,
    isActive,
    isInvalid,
    isLoading,
    hasFailed,

    // ----------------
    update,
    validate,
    process,
    close,
    clear,
    add
  };
});
