// Universal Rules
export const constant = {
  schema: { const: { type: ['null', 'string'] } },
  uischema: {
    name: 'rules.const',
    label: 'Value',
    control: 'text',
    options: {
      hint: 'A constant value that cannot be changed. This value will be used in the form data and will not be visible to the user.',
      prependInnerIcon: 'mdi-lock'
    }
  }
};

export const enumerable = {
  schema: { const: { type: ['null', 'array'] } },
  uischema: {
    name: 'rules.enum',
    label: 'Enum',
    control: 'repeater',
    options: {
      hint: 'A list of constant values that the data must match. The user will usually be presented with a dropdown of these values.',
      prependInnerIcon: 'mdi-lock',
      rowLabel: (context) =>
        (context?.value || `Value ${context?.index}`) + `${context.required ? ' *' : ''}`
    },
    uischema: {
      fields: [
        {
          name: 'value',
          label: 'Value',
          control: 'text',
          options: {
            autofocus: true,
            hint: 'The value that the data must match',
            prependInnerIcon: 'mdi-text'
          }
        }
      ]
    }
  }
};

// ------------------------------
// String Rules : length, pattern, format

export const format = {
  schema: { format: { type: ['null', 'string'], enum: ['email', 'uri'] } }, // must be a valid format
  uischema: {
    name: 'rules.format',
    label: 'Format of the input',
    control: 'select',
    options: {
      hint: 'The validation format/rules to apply to the input. eg. email will validate the input as an email address.',
      prependInnerIcon: 'mdi-format-list-bulleted',
      items: [
        { text: 'Email', value: 'email' },
        { text: 'External URL', value: 'uri' }
        // { text: 'Date', value: 'date' },
        // { text: 'Time', value: 'time' },
        // { text: 'Date Time', value: 'date-time' },
        // { text: 'Duration', value: 'duration' },
        // { text: 'Color', value: 'color' },
        // { text: 'Host Name', value: 'host-name' },
        // { text: 'IP Address', value: 'ip-address' },
        // { text: 'IPv6', value: 'ipv6' },
        // { text: 'URI Reference', value: 'uri-reference' },
        // { text: 'URI Template', value: 'uri-template' },
        // { text: 'JSON Pointer', value: 'json-pointer' },
        // { text: 'Relative JSON Pointer', value: 'relative-json-pointer' },
        // { text: 'Regex', value: 'regex' },
        // { text: 'UUID', value: 'uuid' },
        // { text: 'JSON Schema', value: 'json-schema' },
      ]
    }
  }
};

export const maxLength = {
  schema: { maxLength: { type: ['null', 'number'] } },
  uischema: {
    name: 'rules.maxLength',
    label: 'Maximum Length',
    control: 'number',
    options: {
      hint: 'The maximum number of characters that can be entered into the input.',
      prependInnerIcon: 'mdi-less-than-or-equal'
    }
  }
};

export const minLength = {
  schema: { minLength: { type: ['null', 'number'], minLength: 0 } },
  uischema: {
    name: 'rules.minLength',
    label: 'Minimum Length',
    control: 'number',
    options: {
      hint: 'The minimum number of characters that can be entered into the input.',
      prependInnerIcon: 'mdi-greater-than-or-equal'
    }
  }
};

export const pattern = {
  schema: { pattern: { type: ['null', 'string'] } }, // must match this regex
  uischema: {
    name: 'rules.pattern',
    label: 'Pattern',
    control: 'text',
    options: {
      hint: 'The pattern that is allowed. The value must match this regex.',
      prependInnerIcon: 'mdi-regex'
    }
  }
};

// ------------------------------
// Numeric Rules: range, multiples

export const exclusiveMaximum = {
  schema: { exclusiveMaximum: { type: ['null', 'number'] } },
  uischema: {
    name: 'rules.exclusiveMaximum',
    label: 'Exclusive Maximum',
    control: 'number',
    options: {
      hint: 'The maximum value that is allowed. The value must be less than this number.',
      prependInnerIcon: 'mdi-less-than'
    }
  }
};

export const exclusiveMinimum = {
  schema: { exclusiveMinimum: { type: ['null', 'number'] } },
  uischema: {
    name: 'rules.exclusiveMinimum',
    label: 'Exclusive Minimum',
    control: 'text',
    options: {
      hint: 'The minimum value that is allowed. The value must be greater than this number.',
      prependInnerIcon: 'mdi-greater-than'
    }
  }
};

export const maximum = {
  schema: { maximum: { type: ['null', 'number'] } }, // must be less than or equal to this number
  uischema: {
    name: 'rules.maximum',
    label: 'Maximum',
    control: 'number',
    options: {
      hint: 'The maximum value that is allowed. The value must be less than or equal to this number.',
      prependInnerIcon: 'mdi-less-than-or-equal'
    }
  }
};

export const minimum = {
  schema: { minimum: { type: ['null', 'number'] } }, // must be greater than or equal to this number
  uischema: {
    name: 'rules.minimum',
    label: 'Minimum',
    control: 'number',
    options: {
      hint: 'The minimum value that is allowed. The value must be greater than or equal to this number.',
      prependInnerIcon: 'mdi-greater-than-or-equal'
    }
  }
};

export const multipleOf = {
  schema: { step: { type: ['null', 'number'] } }, // must increment in a multiple of this number
  uischema: {
    name: 'rules.multipleOf',
    label: 'multipleOf',
    control: 'number',
    options: {
      hint: 'The value must increment in a multiple of this number.',
      prependInnerIcon: 'mdi-numeric-positive-1'
    }
  }
};

// ------------------------------
// Array Rules : length, uniqueness

export const maxItems = {
  schema: { maxItems: { type: ['null', 'number'] } },
  uischema: {
    name: 'rules.maxItems',
    label: 'Maximum Items',
    control: 'number',
    options: {
      hint: 'The maximum number of items that can be added to the array.',
      prependInnerIcon: 'mdi-less-than-or-equal'
    }
  }
};

export const minItems = {
  schema: { minItems: { type: ['null', 'number'] } },
  uischema: {
    name: 'rules.minItems',
    label: 'Minimum Items',
    control: 'number',
    options: {
      hint: 'The minimum number of items that can be added to the array.',
      prependInnerIcon: 'mdi-greater-than-or-equal'
    }
  }
};

export const uniqueItems = {
  schema: { uniqueItems: { type: ['null', 'boolean'] } }, // must be unique
  uischema: {
    name: 'rules.uniqueItems',
    label: 'Unique Items Only',
    control: 'checkbox',
    options: {
      hint: 'The items must be unique. No duplicates are allowed.',
      prependIcon: 'mdi-unicorn-variant'
    }
  }
};
