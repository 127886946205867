<template>
  <v-divider v-if="divider" />

  <v-list-subheader
    v-else-if="subheader"
    :title="label"
    v-bind="subheader"
  />

  <v-list-item
    v-else-if="!hasItems && allConditions(conditions)"
    :to="to"
    :prepend-icon="icon?.name || icon"
    v-bind="$attrs"
    :title="label"
    :subtitle="details"
    :active="isActive"
  />

  <v-menu
    v-else-if="allConditions(conditions)"
    :close-on-content-click="true"
    offset-y
    bottom
    left
    nudge-bottom="8"
  >
    <template #activator="{ props: menuProps }">
      <v-list-item
        append-icon="mdi-chevron-down"
        :prepend-icon="icon?.name || icon"
        v-bind="{ ...$attrs, ...menuProps }"
        :title="label || title"
        :active="isActive"
      />
    </template>

    <v-list
      :prepend-icon="icon?.name || icon"
      class="px-0"
      v-bind="$attrs"
    >
      <mtf-nav-list-item
        v-for="(item, index) in items"
        :key="`child-item-${index}`"
        v-bind="{ ...$attrs, ...item }"
      />
    </v-list>
  </v-menu>
</template>

<script>
import { some, startsWith } from 'lodash-es';
import { useCheckConditions } from '@matterific/utils';
import MtfNavListItem from '../components/NavListItem.vue';

export default defineComponent({
  name: 'MtfNavItem',
  components: {
    MtfNavListItem
  },
  props: {
    title: String,
    label: String,
    details: String,
    icon: [String, Object],
    badge: Object,
    to: [String, Object],
    exact: Boolean,
    items: Array,
    conditions: Array,
    divider: Boolean,
    subheader: [Boolean, Object]
  },

  computed: {
    isActive() {
      const { path } = this.$route;
      const active = (this.exact && this.to == path) || (!this.exact && startsWith(path, this.to));

      const contains = some(this.items, ({ exact, to }) => {
        return (exact && to == path) || (!exact && startsWith(path, to));
      });

      return active || contains;
    },
    hasItems() {
      return !!this.items?.length;
    }
  },
  methods: {
    hasBadge(badge) {
      return !!this.useSafeValue(badge, this);
    },

    useSafeValue(item) {
      return useSafeValue(item, this);
    },

    allConditions(conditions) {
      return useCheckConditions(conditions, this.$props);
    }
  }
});
</script>

<!-- <style lang="scss" scoped>
.v-icon,
.navitem:not(.v-list-item--active) {
  color: currentColor !important;
}
</style> -->
