<template>
  <v-avatar
    :size="getSize()"
    variant="tonal"
    v-bind="$attrs"
    @click="trackAction"
  >
    <v-icon
      :size="getSize(1.61803)"
      :icon="icon"
    />
  </v-avatar>
</template>

<script>
export default defineComponent({
  name: 'MtfAvatar',
  props: {
    icon: { type: String },
    size: { type: String, default: 'default' }
  },

  computed: {
    // safeSize() {
    //   return parseInt(this.size) || 80;
    // },
  },
  methods: {
    getSize(ratio = 1) {
      switch (this.size) {
        case 'x-small':
          return Math.round(48 / Math.max(1, ratio));
        case 'small':
          return Math.round(64 / Math.max(1, ratio));
        case 'large':
          return Math.round(96 / Math.max(1, ratio));
        case 'x-large':
          return Math.round(112 / Math.max(1, ratio));
        case 'default':
        default:
          return Math.round(80 / Math.max(1, ratio));
      }
    },

    trackAction() {
      if (this?.$gtm?.push) {
        this.$gtm.push({
          event: null, // Event type [default = 'interaction'] (Optional)
          category: 'Action',
          action: 'click',
          label: this.icon,
          value: this.to,
          noninteraction: false // Optional
        });
      }
    }
  }
});
</script>
