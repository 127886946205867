import { unref } from 'vue';
import { useMatterificSafeReference, isReference } from '@matterific/services/database';
import { useMatterificAuthStore } from '@matterific.auth/store';

import {
  reduce,
  set,
  get,
  isObject,
  isFunction,
  forEach,
  isArray,
  isString,
  isNumber,
  first,
  kebabCase,
  includes,
  isNil,
  map,
  startsWith,
  template
} from 'lodash-es';
import { getActivePinia } from 'pinia';

export const useSafeValue = (value, params) => {
  if (isFunction(value)) {
    const result = value(params);
    return unref(result); // incase the value is a referenced or computed object
  }
  return value;
};

export const useSafeResolve = (action, context, event) =>
  new Promise((resolve, reject) => {
    if (isAsyncFunction(action)) {
      action(context, event).then(resolve, reject);
    } else if (isFunction(action)) {
      resolve(action(context, event));
    } else {
      const error = new Error('Action is Not a Function or a Promise');
      error.statusMessage = 'not-found';
      error.statusCode = 400;
      reject(error);
    }
  });

function isAsyncFunction(func) {
  return func.constructor.name === 'AsyncFunction';
}

// ------------------------------

export const useValues = (values, fallback, context) => {
  if (!values) return fallback;

  const safeValues = isArray(values) ? values : [values];

  let result = values;

  forEach(safeValues, (value) => {
    // check to see if the value is a template, using moustache syntax
    if (isString(value) && includes(value, '{{')) {
      const compiled = template(value, {
        interpolate: /{{([\s\S]+?)}}/g
      });
      result = compiled(context);
      return;
    }

    if (isString(value) || isNumber(value)) {
      result = value;
      return;
    }

    if (isFunction(value)) {
      result = unref(value(context));
      return;
    }

    if (value.auth) {
      const { user, isLoggedIn } = useMatterificAuthStore();
      if (isLoggedIn) {
        result = get(user, value.auth, fallback);
      }
      return;
    }

    if (value.store) {
      // get the active pinia store and then try get the registered store from the state
      const pinia = getActivePinia();
      const store = pinia._s.get(value.store); //get(pinia.state.value, value.store);
      if (!store) {
        result = fallback;
        return; //safety check
      }

      // then check id the store has a matching context,
      // if not, then if it has a matching property or function,
      // otherwise fallback
      result = get(
        store?.state?.value?.context,
        value.context,
        get(store, value.context, fallback)
      );
      if (isFunction(result)) {
        result = result(context) || fallback;
      }
      return;
    }

    // todo multiple contexts?
    if (value.context) {
      result = get(context, value.context, fallback);
      return;
    }
  });
  return result;
};

export const useCleanValues = (values, nullable) =>
  reduce(
    values,
    (result, value, key) => {
      let cleanValue = value; // start with the original value

      if (isPossibleReference(value)) {
        cleanValue = useMatterificSafeReference(value?.$ref || value);
      } else if (isArray(value)) {
        cleanValue = map(value, (itm) => (isObject(itm) ? useCleanValues(itm, nullable) : itm));
      } else if (isObject(value)) {
        cleanValue = useCleanValues(value, nullable);
      }

      const isNotEmpty = nullable || !isNil(cleanValue);
      const prefix = first(kebabCase(key).split('-'));

      if (
        isNotEmpty &&
        !includes(['id'], key) &&
        !startsWith(key, '_') &&
        !startsWith(key, '$') &&
        !includes(['is', 'has', 'can', 'must', 'count'], prefix)
      ) {
        set(result, key, cleanValue);
      }

      return result;
    },
    {}
  );

const isPossibleReference = (val) => {
  // $ref could be a string or an object, depending on the depth of the handdled object
  const isRef = isString(val?.$ref) || isReference(val?.$ref) || isReference(val);
  return isRef;
};
