<template>
  <mtf-flow :flow="flow" />
</template>
<script>
import { defineComponent } from 'vue';
import flow from '@matterific.workflows/matter/flow';

export default defineComponent({
  name: 'MtfWorkflows',
  setup() {
    return {
      flow
    };
  }
});
</script>
