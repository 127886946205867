<template>
  <v-container class="pa-0">
    <v-card
      class="mtf-form-machine"
      v-bind="$attrs"
      flat
      max-width="100%"
    >
      <!--LOADING  -->
      <v-progress-linear
        :active="meta.loading || meta.processing"
        :color="config?.color"
        indeterminate
      />

      <!-- HEADER -->
      <div
        v-if="!meta.loading"
        class="mtf-header"
      >
        <div class="d-flex flex-nowrap">
          <h3
            v-if="schema?.title"
            class="mtf-title"
            v-text="schema.title"
          />

          <v-slide-x-reverse-transition>
            <v-chip
              v-if="meta.invalid && meta.touched"
              class="mtf-has-errors"
              color="error"
              label
            >
              <v-icon
                start
                icon="mdi-alert-circle-outline"
              />
              Contains Errors
            </v-chip>
          </v-slide-x-reverse-transition>
        </div>

        <h4
          v-if="schema?.description"
          class="mtf-description"
          v-text="schema.description"
        />

        <h5
          v-if="schema?.note"
          class="mtf-note"
          v-text="schema.note"
        />
      </div>

      <v-expand-transition
        appear
        mode="out-in"
      >
        <slot
          :meta="meta"
          :actions="actions"
          :uischema="uischema"
          :schema="schema"
          :mode="model"
          :get-field-schema="getFieldSchema"
          :handle-submit="handleSubmit"
          :handle-cancel="handleCancel"
          :is-field="is"
          :bind="bind"
        >
          <v-form
            v-if="meta.available"
            class="mtf-fields"
            @submit.prevent="handleSubmit(actions.submit?.command, actions.submit?.event)"
            @reset.prevent="handleCancel(actions.cancel?.command, actions.cancel?.event)"
          >
            <div
              v-for="field in uischema?.fields"
              :key="field.name"
              class="my-4"
            >
              <component
                :is="is(field, getFieldSchema(field.name, schema), model)"
                v-bind="bind(field, schema, model)"
              />
            </div>

            <mtf-actions
              :actions="actions"
              class="py-8"
            />
          </v-form>
        </slot>
      </v-expand-transition>
    </v-card>
  </v-container>

  <mtf-debug
    v-if="debug"
    :title="`Debug ${config?.singular} Form`"
    :loading="meta.loading"
    :processing="meta.processing"
    :meta="meta"
    :model="model"
    :state="state"
    :error="state.context.error || errors"
  />
</template>

<script>
import { inject, defineComponent } from 'vue';
import { components } from '@matterific/composables/useMatterificFields';
import MtfActions from '@matterific/components/Actions';
import { includes, get } from 'lodash-es';
import { useMatterificForm } from '#imports';

export default defineComponent({
  name: 'MtfFormMachine',
  components: {
    ...components,
    MtfActions
  },
  inheritAttrs: true,
  customOptions: {},
  // --------
  props: {
    id: String,
    dismissable: Boolean,
    timeout: Number
  },
  emits: ['cancel', 'done', 'action'],
  // ----------------

  setup(props, context) {
    const machine = inject('formMachine', null);

    if (!machine) {
      throw new Error('FormMachine must be used within a FormMachineProvider');
    }
    return {
      ...useMatterificForm(machine, context, props.timeout),
      includes,
      get
    };
  },

  computed: {
    actions() {
      return {
        submit: {
          label: 'Save',
          prependIcon: 'mdi-check',
          ...(this.uischema?.actions?.submit || {}),
          disabled: !this.meta.valid || this.meta.processing,
          type: 'submit'
        },
        cancel: {
          label: 'Cancel',
          variant: 'plain',
          ...(this.uischema?.actions?.reset || {}),
          disabled: !this.meta.dirty || this.meta.processing,
          type: 'reset'
        }
      };
    }
  }
});
</script>
