import { defineNuxtPlugin } from '#app';
import { useRuntimeConfig } from '#imports';
import WebFont from 'webfontloader';
import { isEmpty } from 'lodash-es';

// eslint-disable-next-line no-unused-vars
export default defineNuxtPlugin((nuxtApp) => {
  const options = useRuntimeConfig().public.webfontloader;
  if (!isEmpty(options)) WebFont.load(options);
});
