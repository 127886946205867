<template>
  <i
    v-if="icon"
    class="icon d-inline-flex"
    :class="{ 'icon--fill': filled }"
    v-html="icon"
  />
  <mtf-avatar
    v-else-if="avatar"
    :icon="name"
  />
  <v-icon
    v-else-if="name"
    :icon="name"
    v-bind="$attrs"
  />
</template>

<script>
import MtfAvatar from './Avatar.vue';
export default defineComponent({
  name: 'MtfIcon',
  components: {
    MtfAvatar
  },
  props: {
    path: String,
    name: { type: String, required: true },
    filled: { type: Boolean, default: false },
    avatar: { type: Boolean, default: false }
  },
  setup(props) {
    const icon = ref(null);

    watchEffect(async () => {
      try {
        const iconsImport = import.meta.glob('/public/**/*.svg', {
          query: 'raw',
          import: 'default',
          eager: false
        });

        const safePath = props.path ? `${props.path}/` : '';
        const rawIcon = await iconsImport[`/public/${safePath}${props.name}.svg`]();
        icon.value = rawIcon;
      } catch {
        icon.value = null;
      }
    });

    return {
      icon
    };
  }
});
</script>

<style lang="scss">
.icon {
  > svg {
    width: 100%;
    height: 100%;
  }

  &.icon--fill,
  &.icon--fill * {
    *:not(.locked) {
      fill: currentColor !important;
    }
  }
}
</style>
