import { createMongoAbility, AbilityBuilder } from '@casl/ability';
import roles from './roles';

import { isFunction, get } from 'lodash-es';
export { default as roles, actions, subjects } from './roles';

export function createAbilitiesFor(user) {
  const builder = new AbilityBuilder(createMongoAbility);
  const role = get(roles, user?.role);
  if (isFunction(role?.setPerms)) {
    role.setPerms(user, builder);
  } else {
    // default to the guest role
    // console.warn(`Trying to use unknown role "${user?.role}"`, '...revoking all abilites');
    builder.cannot('manage', 'all');
  }

  return builder;
}

export function createAbility(user) {
  const builder = createAbilitiesFor(user);
  return builder.build();
}
