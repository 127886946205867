/**
 * Matterific Services
 * Always return a Promise, and does not do direct communication with the DB
 * This is the layer that handles the business logic for the machines
 * We use the DB service to communicate with the DB
 *
 */

import { unref } from 'vue';

import useMatterificDatabase from './database';
import { useMatterificStore } from '@matterific/store';
import { useMatterificAuthStore } from '@matterific.auth/store';
import { useMatterificSummonStore } from '@matterific.summons/store';
import { useMatterificValidation } from '@matterific/composables/useMatterificValidation';
import { useCheckConditions, useModelBuilder, useSafeResolve, pascalCase } from '@matterific/utils';
import {
  defaults,
  defaultsDeep,
  get,
  isEmpty,
  isNil,
  isObject,
  omit,
  omitBy,
  startCase,
  trim
} from 'lodash-es';
import pluralize from 'pluralize';

export default (entity, parentRef) => {
  const db = useMatterificDatabase(entity, parentRef);
  const entityName = db.entityName;
  const entityRef = db.entityRef;

  // --------------------------------------------
  const authStore = useMatterificAuthStore();
  const summonStore = useMatterificSummonStore();
  const { useMatter } = useMatterificStore();
  const { validate } = useMatterificValidation();

  // --------------------------------------------

  /**
   * Matter is the "blueprint" for the entity
   * Matter consists of the following:
   * - JSON schema
   * - UI schema
   * - Model
   * - Permissions
   * - Conditions
   * - Computed Properties
   * - Badges
   * - Actions
   * This funciton is where we 'materialize' the Matter for the entity from the Definition ,
   * The document can come from the Matterific API or from the Matterific Store
   * - Matterific API will fetch the Definition from the server
   * - Matterific Store will fetch the Definition from the local store/filesystem
   *
   * @param  { Object } context - object containing the matter definition of the entity
   * @returns Promise containing the Matter
   */
  const materialize = (context, event) =>
    new Promise((resolve, reject) => {
      // safety checks
      const entity = event?.entity ? pascalCase(event?.entity) : entityName; // allow override of the entity name
      context = omitBy(context, isNil);
      if (isEmpty(context)) {
        // use our registered matter definitions
        // and get the matter for this entity from the Matterific Store
        context = useMatter(entity);
      } else {
        // if we are given contex tthat has matter, then we need to merge it with the BASE matter from the store
        // so that we ensure we have the full matter definition
        context = defaultsDeep(context, useMatter(entity));
      }

      // then we need to get the matter definition as its constituent parts
      const { config, schema, uischema, permissions, conditions, computedProperties, baseModel } =
        context;
      // ensure we have a valid Matter Name
      if (!config || !schema || !uischema) {
        const error = new Error(`${entity} Config and Schemas Not Found, cant materialize`);
        error.statusMessage = 'invalid';
        error.statusCode = 400;
        reject(error);
      } else {
        // resolve with the full Matter defined!
        resolve({
          config: defaults(config, {
            name: entity,
            color: '',
            icon: 'mdi-folder-question',
            title: startCase(entity),
            description: '',
            note: '',
            limit: null,
            // ------------------------------------
            singular: startCase(entity),
            plural: pluralize(startCase(entity))
          }),
          schema, // this is the full JSON schema with the properties + validation & will be used by the form validation library : ajv
          uischema, // this is the ui schema that renders the JSON schema & will be used by our bespoke form rendering machine
          model: baseModel || useModelBuilder(schema.properties), // create the "BASE" model, if not already provided, based on the JSON schema properties & this will be used to hydrate the entity
          permissions: permissions || {}, // get the permissions required to access the entity & will be used to determine if the user has to access the entity
          conditions: conditions || [], // get the conditions required to enable with the entity & will be used to determine if the user has to access the entity
          computedProperties: computedProperties || {} // get the computed properties, which are values that are computed or calculated from the fields of the entity
        });
      }
    });

  // this is the function that checks ...
  // if the appropriate Matter is defined
  // if the correct auth permissions are met
  // if the correct auth permissions are met
  // if it is not, then we will throw an error
  // NB: users should NOT have more than one pending entry at a time
  /*
   * @param  { Object } context - object containing the matter definition to check
   * @returns Promise
   */
  const check = (context) => {
    let error = null;
    if (!context || !context.schema) {
      const error = new Error('Matter Not Found, cant check!');
      error.statusMessage = 'not-found';
      error.statusCode = 404;
      throw error;
    }

    // todo, intgrate casl based permissions
    if (context?.permissions) {
      const { isLoggedIn, isAdmin } = authStore;
      if (context?.permissions?.auth) {
        if (!isLoggedIn) {
          error = new Error('The user is required to be logged');
          error.statusMessage = 'permission-denied';
          error.statusCode = 401;
        }
      }
      if (context?.permissions?.admin) {
        if (!isAdmin) {
          error = new Error('The user is required to be an admin');
          error.statusMessage = 'permission-denied';
          error.statusCode = 401;
        }
      }
    } else if (!useCheckConditions(context.conditions, context)) {
      error = new Error('The current user does not meet the required conditions');
      error.statusMessage = 'condition-denied';
      error.statusCode = 401;
    }

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  };

  /**
   * This is the function that hydrates/populates the model with the data from the Db
   * We use the Base Model to ensure the model has the correct structure and properties
   * if the id is not found, ie: isNew, then the model is returned as is
   *
   * @param  {string } id - document id of the entity
   * @param  {object} baseModel - object that defines the model structure
   * @param  {boolean} skipFetch - whether the model should be fetched from the db or not
   * @returns Promise containing reactivate model
   */
  const hydrate = async (id, baseModel, skipFetch = false) => {
    let error = null;
    let model = { id, ...unref(baseModel) };
    const safeId = trim(unref(id || model.id));
    let isNew = isEmpty(safeId) || safeId.toLowerCase() == 'new';

    if (!isNew && !skipFetch) {
      const data = await db.fetch(safeId).catch((err) => (error = err));
      model = defaultsDeep(data, model); // hydrate the model with the data from the db AND the base model
    }

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        resolve(model);
      }
    });
  };

  /**
   * This is the function that hydrates/populates the model with the data from the Db
   * We use the Base Model to ensure the model has the correct structure and properties
   * if the id is not found, ie: isNew, then the model is returned as is
   * Params can have the following properties:
   * - filters: array of filter conditions
   * - sorting: array of field(s) to order the records by
   * - limit: the number of records to return
   * - offset: the number of records to skip
   *
   * @param  {object } params - parameters for filtering, ordering and additiona options for the collection query
   * @returns Promise containing listings data
   */
  const hydrateListings = async (params) => {
    return db.find(params);
  };

  /**
   * Transition an entity to a different status
   *
   * @param  {string | number} id - ID of entity to be transitioned
   * @param  {object} status - full status object with  conditions that need to be validated before the transition can be processed
   * @param  {object} model - Model/data of entity to be processed
   * @param  {object } ref - the **optional**  document ref that processed the transition
   * @returns Promise
   */
  const transition = async (context, event) => {
    const { id, ref } = unref(context);
    const safeId = trim(id);
    const model = omit(context, id, ref);
    let error = null;
    const isValid =
      !isEmpty(safeId) &&
      !isEmpty(model) &&
      isObject(event) &&
      !isEmpty(event) &&
      useCheckConditions(event?.conditions, model);

    if (!isValid) {
      error = new Error(`${entityName} ${safeId} Transition Not Valid`);
      error.statusMessage = 'not-valid';
      error.statusCode = 400;
    }

    const data = await db
      .updateStatus(safeId, event.name, model?.status, ref)
      .catch((err) => (error = err));

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  };

  /**
   * duplicate an existing entity
   *
   * @param  {object} model - Model/data of entity to be duplicated
   * @returns Promise
   */
  const duplicate = async (model) => {
    let error = null;
    const newModel = { ...model }; // drop any non inheritable properties, like id, ref, etc
    newModel.title += ' (note)';
    const data = await db.save(null, newModel).catch((err) => (error = err));
    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        resolve(data);
      }
    });
  };

  /**
   * Preview an entity through a summoned component
   *
   * @param  {string | number} context - context of entity to be summoned
   * @param  {object} event - the Summoned Component definition, with config, params, data, etc
   * @returns Promise
   */
  const summon = (context, event) =>
    new Promise((resolve, reject) => {
      const data = event(context);
      if (data?.Component) {
        const summoned = summonStore.add(data);
        summoned.onDismissed().then(resolve, reject);
      } else {
        const error = new Error('Component Not Found, cant summon!');
        error.statusMessage = 'not-found';
        error.statusCode = 404;
        reject(error);
      }
    });

  /**
   * Process an action on an entity
   *
   * @param  {string} action - ID of action to be processed from the valid services
   * @param  {object} context - Model/data of entity to be processed
   * @param  {object } event - event details to be passed to the action for processing
   * @returns Promise
   */
  const process = async (command, context, event) => {
    command = get(services, command);
    if (!command) {
      const error = new Error(`Command: ${command} Not Found!`);
      error.statusMessage = 'not-found';
      error.statusCode = 404;
      throw error;
    }

    const safeResolve = await useSafeResolve(command, context, event); // call the api method based on the action sent for processing
    return safeResolve;
  };

  // --------------------------------------------

  const services = {
    entityRef,
    // ----------------
    getRef: db.getRef,
    fetch: db.fetch,
    exists: db.exists,
    // ----------------
    process,
    // ----------------
    // process commands
    save: ({ id, model }) => db.save(id, model),
    remove: ({ id }) => db.remove(id),
    duplicate: (context) => duplicate(context),
    summon: (context, event) => summon(context, event),
    // ----------------
    transition: (context, event) => transition(context, event),
    // ----------------
    materialize,
    check,
    hydrate,
    hydrateListings,
    validate,
    // ----------------
    checkAuth: authStore.checkAuth
  };

  return services;
};
