import { omit, isNil, isEmpty, get, values, mapValues, find, includes } from 'lodash-es';
import { useSafeValue } from '@matterific/utils';

import * as checkbox from '../components/fields/checkbox';
import * as email from '../components/fields/email';
import * as phone from '../components/fields/phone';
import * as place from '../components/fields/place';
import * as hidden from '../components/fields/hidden';
import * as number from '../components/fields/number';
import * as password from '../components/fields/password';
import * as select from '../components/fields/select';
import * as text from '../components/fields/text';
import * as textarea from '../components/fields/textarea';

import * as lookup from '../components/fields/lookup';
// import * as hierarchical from '../components/fields/hierarchical'; // NB: VUETIFY TREEVIEW COMPONENT NOT AVAILABLE YET

import * as repeater from '../components/fields/repeater';
import * as dependent from '../components/fields/dependent';
import * as flexible from '../components/fields/flexible';
import * as group from '../components/fields/group';

export const components = {
  MtfFieldCheckbox: checkbox.component,
  MtfFieldEmail: email.component,
  MtfFieldPhone: phone.component,
  MtfFieldPlace: place.component,
  MtfFieldHidden: hidden.component,
  MtfFieldNumber: number.component,
  MtfFieldPassword: password.component,
  MtfFieldSelect: select.component,
  MtfFieldText: text.component,
  MtfFieldTextarea: textarea.component,
  MtfFieldUrl: text.component,
  // ---
  MtfFieldLookup: lookup.component,
  // MtfFieldHierarchical: hierarchical.component,
  MtfFieldRepeater: repeater.component,
  MtfFieldDependent: dependent.component,
  MtfFieldFlexible: flexible.component,
  MtfFieldGroup: group.component
};

const schemas = {
  checkbox: checkbox.schema,
  email: email.schema,
  phone: phone.schema,
  place: place.schema,
  hidden: hidden.schema,
  number: number.schema,
  password: password.schema,
  select: select.schema,
  text: text.schema,
  textarea: textarea.schema,
  // --
  lookup: lookup.schema,
  // hierarchical: hierarchical.schema,
  repeater: repeater.schema, // array
  dependent: dependent.schema, // oneOf
  flexible: flexible.schema, // anyOf
  group: group.schema // allOf
};

const uischemas = {
  checkbox: checkbox.uischema,
  email: email.uischema,
  phone: phone.uischema,
  place: place.uischema,
  hidden: hidden.uischema,
  number: number.uischema,
  password: password.uischema,
  select: select.uischema,
  text: text.uischema,
  textarea: textarea.uischema,
  // --
  lookup: lookup.uischema,
  // hierarchical: hierarchical.uischema, // nested
  repeater: repeater.uischema, //array
  dependent: dependent.uischema, // oneOf
  flexible: flexible.uischema, // anyOf
  group: group.uischema // allOf
};

export const useMatterificFields = () => {
  // eslint-disable-next-line no-unused-vars
  function is(uischema, subschema, context) {
    // nb: at this stage the schema should be the subschema for the field
    if (!isEmpty(subschema?.const)) {
      return 'mtf-field-hidden';
    }

    const safeName = get(uischemas, `${uischema?.control}.control`, 'text');
    return `mtf-field-${safeName}`;
  }

  function bind(uischema, schema, context) {
    const fieldSchema = getFieldSchema(uischema?.name, schema);

    // make sure items are safe, as they can be a function
    if (uischema?.options?.items?.length) {
      uischema.options.items = useSafeValue(uischema.options.items, context);
    }

    return {
      name: uischema?.name,
      schema: fieldSchema,
      // ---
      ...omit(uischema, ['control', 'options']),
      ...uischema.options,
      // ---
      readonly: uischema?.options?.readonly || fieldSchema.readonly || !!fieldSchema.const, // todo: make this a function isReadOnly(uischema, fieldSchema, context)
      visible: isVisible(uischema, fieldSchema, context),
      disabled: isDisabled(uischema, fieldSchema, context)
    };
  }

  function getFieldSchema(name, schema) {
    // to get the fields schema, we need to get the subschema
    // from the schema, using the name of the field
    // this is a bit tricky, as the name can be nested, some can be arrays and some can be oneOf, etc

    // this will work for 99% of cases, where the schema is flat
    // if not, we need to do some more work
    // by splitting the name on '.', which denotes a nested object
    // and then joining it with .properties.

    let subschema = {};

    // First check if the schema is an array, and if so, use the items properties
    if (schema?.items?.properties) {
      subschema = get(schema.items.properties, name, {});
    }

    // then check if the schema is a oneOf, and if so, use the @type property of the items
    else if (schema?.oneOf) {
      subschema = find(schema.oneOf, (item) => item.properties['@type']?.const == name);
    }

    // TODO: add support for anyOf and allOf

    // otherwise, the schema's an object, so use the properties
    // the name can be nested, so we need to split it on '.' and join it with .properties.
    else {
      const safeName = name.split('.').join('.properties.');
      subschema = get(schema?.properties, safeName, {});
    }

    // Finally, we need to set the required property if the field is required
    subschema.isRequired = includes(schema?.required, name);

    return subschema;
  }

  function isVisible(uischema, schema, context) {
    const isVisible = useSafeValue(uischema.visible, uischema, context);
    return isNil(isVisible) || isVisible;
  }

  function isDisabled(uischema, schema, context) {
    const isDisabled = useSafeValue(uischema.disabled, uischema, context);
    return isDisabled;
  }

  return {
    getFieldSchema,
    is,
    bind,
    isVisible,
    isDisabled,
    // ---
    schemas,
    uischemas,
    // definitions,
    // ---
    lookups: {
      items: values(
        mapValues(uischemas, ({ label }, key) => ({
          value: key,
          title: label
        }))
      )
    }
  };
};

/*
// Yet to be implemented
// export * as color from '../components/color';
// export * as date from '../components/date';
// export * as datetimeLocal from '../components/datetimeLocal';
// export * as month from '../components/month';
// export * as time from '../components/time';
// export * as uri from '../components/uri';
// export * as uriReference from '../components/uri-reference';
// export * as week from '../components/week';
// export * as file from '../components/file';
// export * as radio from '../components/radio'
// export * as checkboxGroup from '../components/radio'
// export * as switch from '../components/switch'
// export * as range from '../components/range'
// export * as phone from '../components/phone'
// export * as address from '../components/address'
// export * as combobox from '../components/combobox'
// export * as dateRange from '../components/dateRange'
// export * as datetimeRange from '../components/datetimeRange'


//   // Yet to be implemented
//   // radio:{ title: 'Radio' ,type: 'text', description: '', hasHint: false, hasMin: false, hasMax: false, hasValues: true},
//   // switch:{ title: 'Switch', type: 'boolean', description: '',hasHint: false, hasMin: false, hasMax: false },
//   // range:{ title: 'Range', type: 'number', description: '',hasHint: true, hasMin: true, hasMax: true },
//   // phone:{ title: 'Phone', type: 'string', description: 'An input that will allow for valid phone numbers', hasHint: true, hasMin: true, hasMax: true },
//   // address:{ title: 'Address', type: 'string', description: 'A Google Maps enable address lookup' },
//   // combobox:{ title: 'Combo Box', type: 'string', description: 'A Combo Box is an autocomplete dropdown thats also allows the user to enter values that do not exist within the provided items. A combination of a Dropdown and Textbox', hasHint: true, hasMin: true, hasMax: true },
//   // dateRange:{ title: 'Date', type: 'array', description: 'A Calendar for users to select a date', hasHint: true, hasMin: true, hasMax: true },
//   // datetimeRange:{ title: 'Time', type: 'array', description: 'A Calendar for users to select a time', hasHint: true, hasMin: true, hasMax: true },
//   // our custom inputs
// };
*/
