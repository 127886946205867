<template>
  <!-- HEADER -->
  <mtf-listings-header
    v-model="filters"
    v-bind="headerSchema"
    :loading="isLoading"
    :disabled="isProcessing"
    :title="title"
    @action="process"
  />

  <!-- NO RESULTS -->
  <mtf-empty
    v-if="isEmpty && !isLoading"
    v-bind="emptySchema"
    @action="process"
  />

  <!-- LISTINGS -->
  <mtf-listings
    v-if="listings"
    :items="listings"
    :size="safeSize"
    :loading="isLoading"
    :color="$attrs?.color"
  >
    <template #item="{ item }">
      <component
        :is="safeCard"
        :item="item"
        v-bind="{ ...cardSchema, ...$attrs }"
        :loading="isLoading"
        :disabled="isProcessing"
        @toggle:showing="toggleShowing"
        @toggle:active="toggleActive"
        @action="process"
        @cancel="cancel"
      />
    </template>
  </mtf-listings>

  <!-- FOOTER -->
  <mtf-listings-footer v-if="isEmpty" />

  <mtf-debug
    v-if="debug"
    :title="`Debug ${config?.plural}`"
    :loading="isLoading"
    :processing="isProcessing"
    :meta="meta"
    :state="state"
    :error="state?.context?.error"
  />
</template>

<script>
import { computed, ref } from 'vue';
import { useSelector } from '@xstate/vue';
import { filter, forEach, isNil, isObject, mapValues, omit } from 'lodash-es';
import { useMatterificEntitiesMachine, useMatterificEntriesMachine } from '#imports';

import mtfListings from '@matterific/components/Listings.vue';
import mtfListingsCard from '@matterific/components/ListingsCard.vue';
import MtfListingsHeader from '@matterific/components/ListingsHeader';
import MtfListingsFooter from '@matterific/components/ListingsFooter';

export default defineComponent({
  name: 'MtfManageEntities',
  components: {
    mtfListings,
    mtfListingsCard,
    MtfListingsHeader,
    MtfListingsFooter
  },
  inheritAttrs: true,
  customOptions: {},
  props: {
    parent: { type: String },
    entity: { type: String, required: true },
    size: Number,
    title: String
  },
  emits: ['action', 'cancel'],

  setup(props, { emit }) {
    // --------
    // PRIVATE

    // -------- MACHINE/ENTITY

    const { state, service, send } = props.parent
      ? useMatterificEntriesMachine(props.entity, props.parent, props)
      : useMatterificEntitiesMachine(props.entity, props);

    const debug = useSelector(service, (state) => state.context.debug);
    const config = useSelector(service, (state) => state.context.config);

    const listings = useSelector(
      service,
      (state) => reactive(state.context.listings || []) // reactive to allow toggling
    );

    const headerSchema = useSelector(service, (state) => {
      // We have no results theres no point showing options to search or filter
      if (!state.context.documents?.length)
        return omit(state.context.uischema?.header, ['query', 'filters']);

      // --------
      // query and filters may be dynamic so we need to use the safe value
      // and we use the raw documents to allow for dynamic values
      // so they are not affected by the current query/filters
      // maybe we can add a flag as to whether we allow the query/filters to affect the values

      const query = useSafeValue(state.context.uischema?.header?.query, state.context.documents);

      const filters = mapValues(state.context.uischema?.header?.filters, (values) =>
        useSafeValue(values, state.context.documents)
      );
      return {
        ...state.context.uischema?.header, //all other values
        query,
        filters
      };
    });

    const filters = computed({
      get: () => ({
        query: state.value.context.query,
        filters: state.value.context.filters
      }),
      set: (value) => {
        send('filter', value);
      }
    });

    const cardSchema = useSelector(service, (state) => state.context.uischema?.card);

    const emptySchema = computed(() => ({
      ...messages.value?.empty,
      title: useSafeValue(messages.value?.empty?.title, {
        isFiltered: isFiltered.value,
        config: config.value
      }),
      description: useSafeValue(messages.value?.empty?.description, {
        isFiltered: isFiltered.value,
        config: config.value
      }),
      note: useSafeValue(messages.value?.empty?.note, {
        isFiltered: isFiltered.value,
        config: config.value
      }),
      actions: filter(messages.value?.empty?.actions, (action) =>
        useCheckConditions(action?.conditions, {
          isFiltered: isFiltered.value,
          config: config.value
        })
      )
    }));

    const messages = useSelector(service, (state) => state.context.uischema?.messages);

    // -------- GETTERS
    const isLoading = computed(
      () => !state.value || ['materializing', 'pending', 'hydrating'].some(state.value.matches)
    );

    const isProcessing = computed(() => ['processing'].some(state.value.matches));

    const isFiltered = computed(() => !!filters.value.query || !!filters.value.filters);

    const isEmpty = computed(() => ['available.empty'].some(state.value.matches));

    // -------- METHODS

    function toggleShowing(item) {
      // if we are showing/not showing we must match the active state
      // so thats why we sync listing.isActive
      forEach(listings.value, (listing) => {
        if (listing.id == item.id) {
          const value = !listing.isShowing;
          listing.isShowing = value;
          listing.isActive = value;
        } else {
          listing.isShowing = false;
          listing.isActive = false;
        }
      });
    }

    function toggleActive(item, value) {
      forEach(listings.value, (listing) => {
        if (listing.id == item.id) {
          if (listing.isShowing) {
            // if we are showing then we are not definitely active!
            // so thats why we set listing.isActive
            listing.isActive = true;
          } else {
            listing.isActive = isNil(value) ? !listing.isActive : value;
            // if we are active then we are not necessarily showing!
            // so thats why we don't set listing.isShowing
          }
        } else {
          listing.isActive = false;
          // but if we are not active then we are definitely not showing!
          // so thats why we do set listing.isShowing
          listing.isShowing = false;
        }
      });
    }

    function process({ command, context, event }) {
      if (!command) return;
      if (!isObject(context)) {
        context = {};
      }
      // ensure we add our parent to the context
      Object.defineProperty(context, 'parent', {
        value: props.parent
      });

      send('process', { command, context, event });

      // allow the event to 'bubble up' to the parent
      emit('action', { command, context, event });
    }

    function cancel({ command, context, event }) {
      if (!command) return;
      if (!isObject(context)) {
        context = {};
      }
      // ensure we add our parent to the context
      Object.defineProperty(context, 'parent', {
        value: props.parent
      });

      send('process', { command, context, event });

      // allow the event to 'bubble up' to the parent
      emit('cancel', { command, context, event });
    }
    // -------- DEBUG
    const meta = ref();

    return {
      debug,
      // --- matter
      state,
      headerSchema,
      cardSchema,
      emptySchema,
      meta,
      config,
      // --- listings
      listings,
      filters,
      // --- getters
      isLoading,
      isProcessing,
      isFiltered,
      isEmpty,
      // --- methods
      toggleShowing,
      toggleActive,
      process,
      cancel
    };
  },

  computed: {
    safeCard() {
      return this.cardSchema?.Component || 'mtf-listings-card';
    },
    safeSize() {
      return this.size || this.cardSchema?.size || 4;
    } // todo: get a default value from the matter
  }
});
</script>
