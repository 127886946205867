import { computed, ref, unref } from 'vue';
import { defineStore } from 'pinia';
import { get, set, defaultsDeep, isFunction } from 'lodash-es';
import { pascalCase } from '@matterific/utils';
import { useAppConfig } from '#imports';

export const useMatterificStore = defineStore('matterific', () => {
  // --------
  // STATE

  // const themes = ref(['light', 'dark',]),
  // const locale = ref('en'),
  // const theme = ref('light'),
  const matter = ref({});
  const blueprint = useAppConfig()?.blueprint;
  // our default App Settings
  const DEFAULT_SETTINGS = {
    pending: false,
    header: {
      ...(blueprint.header || []),
      menuPrimary: 'primary',
      menuDrawer: 'drawer'
    },
    rail: {
      ...(blueprint.rail || []),
      menu: 'rail'
    },
    sidebar: {
      ...(blueprint.sidebar || []),
      menu: 'sidebar'
    },
    footer: {
      ...(blueprint.footer || []),
      menuFooter: 'footer',
      menuCompliance: 'compliance',
      menuSocial: 'social'
    }
  };

  const settings = ref(DEFAULT_SETTINGS);

  // --------
  // ACTIONS
  // always use the default settings object as the base
  // then merge in any new settings
  // this prevents any missing settings from being lost
  // or any hanging settings from being left behind
  function updateSettings(value) {
    // settings.value = assign({}, DEFAULT_SETTINGS, unref(value));
    settings.value = defaultsDeep({}, unref(value), DEFAULT_SETTINGS);
  }

  async function defineMatter(entity, definitionPromise) {
    const safeEntity = pascalCase(entity);
    if (get(matter.value, safeEntity)) {
      throw new Error(`Matterific: ${safeEntity} already defined`);
    }
    // add each of our module matter definitions to the global matterific store
    set(matter.value, safeEntity, definitionPromise);
  }

  // ---------------------------------------------------------------------------
  // PUBLIC
  return {
    // STATE : Mutable
    // locale,
    // theme,
    settings,

    // GETTERS : Immutable

    // themes: computed(() => themes.value),
    matter: computed(() => matter.value),

    // ACTIONS
    updateSettings,
    defineMatter,
    getMatter: (entity) => get(matter.value, pascalCase(entity)),
    useMatter: (entity) => {
      const definitionPromise = get(matter.value, pascalCase(entity));
      return isFunction(definitionPromise) ? definitionPromise() : definitionPromise;
    }
  };
});
