import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin(({ $router }) => {
  $router.options.scrollBehavior = (to, from, savedPosition) => {
    console.log('scrollBehavior', { savedPosition, to });

    if (savedPosition) {
      return savedPosition;
    }

    const findEl = async (hash, x) => {
      return (
        document.querySelector(hash) ||
        new Promise((resolve) => {
          if (x > 30) return resolve(); // safety: avoid infinite loops with 30 attempts = ±3 seconds
          setTimeout(() => resolve(findEl(hash, ++x || 1)), 100);
        })
      );
    };

    if (to.hash) {
      findEl(to.hash).then((el) => {
        if ('scrollBehavior' in document.documentElement.style) {
          return window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
        } else {
          return window.scrollTo(0, el.offsetTop);
        }
      });
    }

    return { x: 0, y: 0 };
  };
});
