import payload_plugin_qLmFnukI99 from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_ftx86CUdf5 from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.mjs";
import vuetify_icons_RMzWu406ID from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/domdacosta/Dev/Matterific/insight-projects/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_wMKVLl9fyU from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import plugin_client_Fdvg3o8568 from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/@nuxtjs/web-vitals/dist/runtime/plugin.client.mjs";
import titles_dw2T9lEw4h from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_fcc0iSNKKy from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_izaWKZ8rEu from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/Users/domdacosta/Dev/Matterific/insight-projects/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_sIIuMyeNti from "/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/modules/webfontloader/plugin.client.js";
import plugin_iuZLcHUHrF from "/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/modules/Auth/casl/plugin.js";
import plugin_te8ICwkj8b from "/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/modules/Content/matter/plugin.js";
import plugin_Ahtdo6Ozkj from "/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/modules/Forms/matter/plugin.js";
import plugin_G1S2AQhdWz from "/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/modules/Workflows/matter/plugin.js";
import router_wOfKYw31oW from "/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/plugins/router.js";
import xstate_client_u28cJkFPaz from "/Users/domdacosta/Dev/Matterific/insight-projects/layers/matterific/plugins/xstate.client.js";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/Users/domdacosta/Dev/Matterific/insight-projects/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  vuetify_i18n_ftx86CUdf5,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_no_client_hints_wMKVLl9fyU,
  plugin_client_Fdvg3o8568,
  titles_dw2T9lEw4h,
  defaultsWaitI18n_fcc0iSNKKy,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_client_sIIuMyeNti,
  plugin_iuZLcHUHrF,
  plugin_te8ICwkj8b,
  plugin_Ahtdo6Ozkj,
  plugin_G1S2AQhdWz,
  router_wOfKYw31oW,
  xstate_client_u28cJkFPaz,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]