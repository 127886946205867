import { useMatterific } from '@matterific/composables/useMatterific';
import {
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail
} from 'firebase/auth';
import matterificServices from '@matterific/services/index.js';
import roles, { fallback } from '../casl/roles';
import { get } from 'lodash-es';

export default () => {
  const { auth, userRef } = useMatterific();

  const entity = 'user';
  const api = matterificServices(entity); // the api that will do CRUD on the entity

  // ---------------------------------------------------------------------------

  const loginForm = (email, password, isNew) => {
    // by default we will try to login, but if the user does not exist, we will try to register
    const action = isNew ? createUserWithEmailAndPassword : signInWithEmailAndPassword;
    return action(auth, email, password).catch((error) => throwMessage(error, { email, password }));
  };

  const loginPopup = (provider) =>
    signInWithPopup(auth, provider).catch((error) => throwMessage(error));

  const forgotPassword = (email) =>
    sendPasswordResetEmail(auth, email).then(() => throwMessage({ code: 'auth/reset' }));

  const throwMessage = async (error, context) => {
    let customError;
    // ...
    switch (error?.code) {
      case 'auth/popup-closed-by-user':
        customError = new Error('Cancelled or timed out');
        customError.statusMessage = 'timeout';
        customError.statusCode = 408;
        break;

      case 'auth/user-not-found':
        if (context) return loginForm(context.email, context.password, true); //try register if we have a context
        customError = new Error('Unknown Email');
        customError.statusMessage = 'not-found';
        customError.statusCode = 404;
        break;

      case 'auth/internal-error':
        // this is our Firebase Functions Auth error, based on the users email NOT registered in our api
        customError = new Error(
          'Unauthorized email, If you believe this to be a mistake, please contact us'
        );
        customError.statusMessage = 'unauthorized';
        customError.statusCode = 401;
        break;

      case 'auth/email-already-in-use':
        customError = new Error('Email already in use');
        customError.statusMessage = 'invalid';
        customError.statusCode = 400;
        break;

      case 'auth/wrong-password':
        customError = new Error('Incorrect username or password');
        customError.statusMessage = 'invalid';
        customError.statusCode = 400;
        break;

      case 'auth/reset':
        // workaround to show the response as an alert
        customError = new Error('Please Check your email for a reset link');
        customError.statusMessage = 'ok';
        customError.statusCode = 200;
        break;

      default:
        customError = new Error(`Unknown error ${error?.code}`);
        customError.statusMessage = 'invalid';
        customError.statusCode = 400;
    }

    console.error('Auth', 'loginForm', 'Error', error, customError);

    if (customError) throw customError;
  };

  function setUser(context) {
    return new Promise((resolve, reject) => {
      // auth object is already set on the app context by authChecker service
      context.auth
        .getIdTokenResult()
        .then(async (response) => {
          const { claims } = response;
          // create a user object based on the claims
          return {
            uid: claims.user_id,
            title: claims.displayName || claims.name,
            email: claims.email,
            avatar: claims.picture
          };
        })
        .then(async (user) => {
          // Now assosciate the user with the correct role + permissions from our Account's User API

          // 1st try get the user from the api
          const userDoc = await api.fetch(user.uid).catch((error) => reject(error));

          // then ensure we have a valid, defined role, otherwise fallback to the defaultRole
          const role = get(roles, userDoc?.role, fallback);
          userDoc.role = role;

          // if the user exists in the api, then return it
          if (userDoc) return userDoc;

          // otherwise, then create it and return it
          return api
            .save({
              id: user.uid,
              model: {
                user: userRef(),
                role: role.name
              }
            })
            .then((user) => api.fetch(user.uid).catch((error) => reject(error)))
            .catch((error) => reject(error));
        })
        .then(resolve, reject);
    });
  }
  // ---------------------------------------------------------------------------

  // todo
  // async getRules(context, uid) {
  //   console.debug('Auth', 'getRules', { uid });
  //   // TODO: Create a full rule Service to return the current users actual permissions
  //   // for now we will grant superadmin priveledges by using the CASL special keywords to give full access to everything!
  //   return [
  //     addAbility('manage', 'all'),
  //     // addAbility('VIEW', 'ACCOUNT'),
  //     // addAbility('edit', 'ACCOUNT', {
  //     // id: { $in: ['XdWIeEqfUlp4C146pPcn'] },
  //     // }),
  //     // addAbility('add', 'ACCOUNT'),
  //   ];
  // },

  // ---------------------------------------------------------------------------

  return {
    // wrap the onAuthStateChanged hook in a promise and immediately unsubscribe when triggered
    authChecker: () =>
      new Promise((resolve, reject) => {
        if (!auth) return reject();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          unsubscribe();
          return user ? resolve(user) : reject();
        });
      }),

    loader: setUser,

    login: (context, { provider, data }) => {
      switch (provider) {
        case 'email':
        default:
          return loginForm(data.email, data.password);

        case 'google':
          return loginPopup(new GoogleAuthProvider(), GoogleAuthProvider);
      }
    },

    logout: () => signOut(auth),

    forgotPassword: (context, { email }) => forgotPassword(email)

    // TODO: send verification email?
    // TODO: resend verification email?
  };
};
