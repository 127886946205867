

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"blueprint":{"defaults":{"VBtn":{"rounded":"pill","variant":"tonal"},"VBtnGroup":{"rounded":"xl","VBtn":{"rounded":null}},"VCard":{"rounded":"lg"},"VChip":{"rounded":"pill"}},"theme":{"defaultTheme":"dark","themes":{"dark":{"dark":true,"colors":{"accent":"#7C4DFF","background":"#11111F","error":"#C2185B","overlay":"#1A222A","primary":"#4EC3E0","secondary":"#F06292","surface":"#11111F","tertiary":"#177FA3","transparent":"#11111F"}}}}},"ssr":true}
  
  
  
  
  return options
}

