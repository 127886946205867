<template>
  <v-app>
    <mtf-summons />

    <mtf-rail
      v-bind="settings?.rail"
      :loading="settings.pending"
    />

    <mtf-sidebar
      v-bind="settings?.sidebar"
      :loading="settings.pending"
    />

    <mtf-header
      v-bind="settings?.header"
      :loading="settings.pending"
    />

    <mtf-auth-drawer v-bind="settings?.auth" />

    <v-main
      id="main"
      role="main"
      :class="['position-relative']"
    >
      <mtf-messages />

      <v-container
        fluid
        class="pa-0 justify-center align-start"
      >
        <seo-kit />

        <!--
        <og-image-screenshot />
         -->

        <nuxt-page />
      </v-container>

      <mtf-footer
        v-bind="settings?.footer"
        :loading="settings.pending"
      />
    </v-main>
  </v-app>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useMatterificStore } from '@matterific/store';
import { useMatterificAuthStore } from '@matterific.auth/store';

const store = useMatterificStore();
const { settings } = storeToRefs(store);
useMatterificAuthStore(); //ensure the auth store is loaded
</script>
