import { defineAsyncComponent } from 'vue';
import { constant } from '../rules';

// the component for this field
export const component = defineAsyncComponent(() => import('./FieldHidden.vue'));

// the JSON Schema Definition for this field
export const schema = {
  type: 'object',
  required: [],
  properties: {
    '@type': { const: 'hidden' },
    options: {
      type: 'object',
      required: [],
      properties: {}
    },
    rules: {
      type: 'object',
      required: [],
      properties: {
        type: { const: 'string' },
        ...constant.schema
      }
    }
  }
};

// the Ui Schema for this field
export const uischema = {
  value: 'hidden', // corresponds to the @type property in the JSON Schema
  title: 'Hidden',
  control: 'hidden',
  uischema: {
    fields: [constant.uischema]
  }
};
