import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useMatterificUsers } from '#imports';
import { map } from 'lodash-es';

export const useMatterificUserStore = defineStore('matterific.users', () => {
  const api = useMatterificUsers();
  const listings = ref([]);

  const items = computed(() =>
    map(listings.value, (item) =>
      Object.defineProperties(
        {
          title: item.title || item.displayName, // legacy fallbacks
          avatar: item.avatar || item.photoURL, // legacy fallbacks
          subtitle: item.role
        },
        {
          id: { value: item.id || item.uid }, // legacy fallbacks
          $ref: {
            get: () => item.$ref
          }
        }
      )
    )
  );

  api.hydrateListings().then((data) => (listings.value = data));

  return {
    items // used for lookups, autocomplete, select, etc.
  };
});
