import { defineAsyncComponent } from 'vue';
import { autocomplete, autofocus, hint, placeholder } from '../options';
import { multipleOf, minimum, maximum } from '../rules';

// the component for this field
export const component = defineAsyncComponent(() => import('./FieldNumber.vue'));

// the JSON Schema Definition for this field
export const schema = {
  type: 'object',
  required: [],
  properties: {
    '@type': { const: 'number' },
    options: {
      type: 'object',
      required: [],
      properties: {
        ...autocomplete.schema,
        ...autofocus.schema,
        ...hint.schema,
        ...placeholder.schema
      }
    },
    rules: {
      type: 'object',
      required: [],
      properties: {
        type: { const: 'number' },
        ...minimum.schema,
        ...maximum.schema,
        ...multipleOf.schema
      }
    }
  }
};

// the Ui Schema for this field
export const uischema = {
  value: 'number', // corresponds to the @type property in the JSON Schema
  title: 'Number',
  control: 'number',

  uischema: {
    fields: [
      autofocus.uischema,
      placeholder.uischema,
      hint.uischema,
      autocomplete.uischema,
      minimum.uischema,
      maximum.uischema,
      multipleOf.uischema
    ]
  }
};
