<template>
  <v-list
    v-if="(hasOverflow || hasMenu) && allConditions"
    v-bind="$attrs"
  >
    <mtf-nav-list-item
      v-for="(item, index) in overflowItems"
      :key="`overflow-item-${index}`"
      v-bind="{ ...$attrs, ...item }"
    />

    <v-divider v-if="hasOverflow && hasMenu" />

    <mtf-nav-list-item
      v-for="(item, index) in items"
      :key="`drawer-item-${index}`"
      v-bind="{ ...$attrs, ...item }"
    />
  </v-list>
</template>

<script>
import MtfNavListItem from '../components/NavListItem.vue';
import { useCheckConditions } from '@matterific/utils';

export default defineComponent({
  name: 'MtfList',
  components: {
    MtfNavListItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    overflowItems: {
      type: Array,
      default: () => []
    },

    conditions: Array
  },
  computed: {
    hasOverflow() {
      return !!this.overflowItems?.length;
    },
    hasMenu() {
      return !!this.items?.length;
    },
    allConditions() {
      return useCheckConditions(this.conditions, this.items.concat(this.overflowItems));
    }
  }
});
</script>
