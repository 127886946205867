<template>
  <v-card
    :variant="safeColor ? 'tonal' : 'flat'"
    :color="safeColor"
    class="text-center"
    density="compact"
    v-bind="$attrs"
  >
    <v-img
      v-if="statusCode"
      class="align-end text-white"
      height="400"
      :src="`/${statusCode}.svg`"
    />

    <v-card-title v-if="safeHeader">
      {{ safeHeader }}
    </v-card-title>

    <v-card-text>
      <h3 class="text-h3 ma-4">
        {{ safeTitle }}
      </h3>

      <h4 class="text-h5 ma-4">
        {{ safeDescription }}
      </h4>

      <p>{{ safeNote }}</p>
      <slot />
    </v-card-text>

    <v-card-actions class="justify-center mb-4">
      <slot name="actions" />
      <v-btn
        to="/"
        prepend-icon="mdi-chevron-left"
      >
        Go Back Home
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { useDisplay } from 'vuetify';

export default defineComponent({
  name: 'MtfError',
  inheritAttrs: true,
  customOptions: {},
  props: {
    statusCode: {
      type: Number,
      default: 404
    },
    header: String,
    icon: String,
    title: String,
    description: String,
    note: String,
    actions: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },

  computed: {
    safeHeader() {
      return this.header || '';
    },
    safeIcon() {
      return this.icon || 'mdi-magnify';
    },
    safeTitle() {
      return this.title || 'Oops!';
    },
    safeDescription() {
      return this.description || 'Sorry, but we could not find the page you are looking for';
    },
    safeNote() {
      return this.note || 'If you believe this to be a mistake, please contact us';
    },
    safeColor() {
      return this.$attrs?.color || '';
    }
  }
});
</script>
