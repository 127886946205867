export default defineAppConfig({
  blueprint: {
    rail: {
      // background: 'primary'
    },
    sidebar: {
      // location: 'start'
      // theme: 'dark'
    },
    header: {
      // background: 'secondary',
      // color: 'primary',
      // backgroundActive: 'primary',
      // colorActive: 'secondary'
    },
    footer: {
      // background: 'primary',
      color: 'secondary'
    }
  }
});
