import matterificServices from '@matterific/services';
import { map } from 'lodash-es';

// Content Module uses the Matterific API Composable to fetch data from the Database
// This module fetches data from the Pages Collection
export const useMatterificUsers = () => {
  const { hydrateListings } = matterificServices('user');

  // The user collection  consists of  roles and a user reference
  //  we dont want to return the role, and  only return the hydrated reference
  return {
    hydrateListings: async () =>
      hydrateListings({ maxDepth: 2 }).then((listings) => map(listings, 'user'))
  };
};
