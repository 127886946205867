<template>
  <client-only>
    <aside
      v-show="message"
      class="app-messages"
    >
      <component
        :is="`mtf-message-${message.type}`"
        v-if="message"
        :key="`message-${message.id}`"
        :model-value="isActive"
        v-bind="message.props"
        :close-icon="message.closeIcon"
        :close-label="message.closeLabel"
        @update:model-value="!$event && doClose()"
      >
        <v-alert
          :text="message.error"
          type="error"
          variant="flat"
          density="compact"
          :model-value="hasFailed"
          class="mb-4 w-100"
        />

        <div
          v-if="message.text"
          v-html="message.text"
        />

        <template #actions>
          <v-btn
            variant="plain"
            :disabled="isLoading"
            :prepend-icon="message.closeIcon"
            @click="doClose"
          >
            {{ message.closeLabel }}
          </v-btn>

          <v-spacer />
          <template
            v-for="(action, actionId) in message.actions"
            :key="`action-${actionId}`"
          >
            <v-btn
              v-if="useCheckConditions(action.conditions, action)"
              v-bind="action.props"
              :disabled="isLoading || isInvalid || hasFailed"
              :loading="isLoading"
              @click="doAction(actionId)"
            >
              {{ action.label || 'ok' }}
            </v-btn>
          </template>
        </template>
      </component>
    </aside>
  </client-only>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useMatterificMessageStore } from '@matterific.messages/store';
import { useCheckConditions } from '@matterific/utils/conditions';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'MtfMessages',
  components: {
    // MtfFloatingRail: defineAsyncComponent(() => import('@matterific/components/FloatingRail.vue')),
    MtfMessageAlert: defineAsyncComponent(() => import('@matterific/components/Alert.vue')),
    MtfMessageBanner: defineAsyncComponent(() => import('@matterific/components/Banner.vue')),
    MtfMessageDialog: defineAsyncComponent(() => import('@matterific/components/Dialog.vue')),
    MtfMessageSnackbar: defineAsyncComponent(() => import('@matterific/components/Snackbar.vue'))
  },
  inheritAttrs: false,
  customOptions: {},

  setup() {
    const messageStore = useMatterificMessageStore();
    const { message, isActive, isInvalid, isLoading, hasFailed } = storeToRefs(messageStore);

    const timestamp = ref(Date.now());

    return {
      timestamp,
      message,
      // ------------------------------
      isActive,
      isInvalid,
      isLoading,
      hasFailed,
      // ------------------------------
      doUpdate: messageStore.update,
      doValidate: messageStore.validate,
      doAction: messageStore.process,
      doClose: messageStore.close,
      clear: messageStore.clear,
      add: messageStore.add,
      // ------------------------------
      useCheckConditions
    };
  }
});
</script>

<style lang="scss" scoped>
$header-height: 64px !default;

.app-messages {
  .shinethrough & {
    margin-top: -$header-height;
  }

  top: 0;
  position: sticky !important;
  z-index: 1007;
}
</style>
