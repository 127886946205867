<template>
  <v-divider v-if="divider" />

  <v-list-subheader
    v-else-if="subheader"
    :title="label"
    v-bind="subheader"
  />

  <v-list-item
    v-else-if="!hasItems && allConditions(conditions)"
    :to="to"
    :prepend-icon="icon?.name || icon"
    v-bind="$attrs"
    :title="label"
    :subtitle="details"
    :active="isActive"
  />

  <v-list
    v-else-if="allConditions(conditions)"
    :prepend-icon="icon?.name || icon"
    class="px-0"
    v-bind="$attrs"
  >
    <v-list-group
      value="items"
      :fluid="$attrs?.fluid"
    >
      <template #activator="{ props }">
        <v-list-item
          v-bind="props"
          :prepend-icon="icon?.name || icon"
          :active="isActive"
          :title="label || title"
          :subtitle="details"
        />
      </template>

      <template
        v-for="(item, index) in items"
        :key="`childItem-${index}`"
      >
        <v-divider v-if="item.divider" />

        <v-list-subheader
          v-else-if="item.subheader"
          :title="item.label"
          v-bind="item.subheader"
        />

        <v-list-item
          v-else
          :prepend-icon="item.icon?.name || item.icon"
          v-bind="item"
          :title="item.label"
          :subtitle="item.details"
        />
      </template>
    </v-list-group>
  </v-list>
</template>

<script>
import { some, startsWith } from 'lodash-es';
import { useCheckConditions, useSafeValue } from '@matterific/utils';

export default defineComponent({
  name: 'MtfNavListItem',
  props: {
    title: String,
    label: String,
    details: String,
    icon: [String, Object],
    badge: Object,
    to: [String, Object],
    exact: Boolean,
    items: Array,
    conditions: Array,
    divider: Boolean,
    subheader: [Boolean, Object]
  },

  computed: {
    hasItems() {
      return !!this.items?.length;
    },

    isActive() {
      const { path } = this.$route;
      const active = (this.exact && this.to == path) || (!this.exact && startsWith(path, this.to));

      const contains = some(this.items, ({ exact, to }) => {
        return (exact && to == path) || (!exact && startsWith(path, to));
      });

      return active || contains;
    }
  },
  methods: {
    hasBadge(badge) {
      return !!this.useSafeValue(badge, this);
    },

    useSafeValue(item) {
      return useSafeValue(item, this);
    },

    allConditions(conditions) {
      return useCheckConditions(conditions, this.$props);
    }
  }
});
</script>
